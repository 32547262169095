import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import DropdownSelect from '../../shared/components/DropdownSelect';
import { RECALCULATION } from '../../shared/constants/Constants';

const VerticalDetail = ({ materialTypes, venderTypesData, conditionValue }) => {
  const { errors, values, setFieldValue } = useFormikContext();

  const extractedMaterialType = materialTypes?.map((item) => {
    return { id: item.id, value: item.name };
  });

  const conditionValues = conditionValue?.map((item, index) => {
    return { id: index + 1, value: item };
  });

  const handleChangeValue = (name, selectedOption, isMulti = false) => {
    setFieldValue(
      name,
      !isMulti ? selectedOption?.value : selectedOption?.map((condition) => condition)
    );
  };
  return (
    <>
      <hr />
      <h5 className="mb-3 mt-3 p-2">Vertical, Vendor and Conditions Detail</h5>
      <hr />
      <div>
        <Form.Group className="mb-3">
          <div className="d-flex">
            <div style={{ width: '30%', marginRight: '10px' }}>
              <Form.Label>Vertical Type</Form.Label>
              <DropdownSelect
                name="verticalType"
                value={values?.materialType}
                onChange={(e) => handleChangeValue('materialType', e)}
                style={{ borderRadius: '20px' }}
                options={extractedMaterialType}
                getOptionLabel={(e) => e.value}
                getOptionValue={(e) => e.value}
                valueKey="value"
              />
              <Form.Control.Feedback type="isInvalid">
                <span style={{ color: '#ff7272' }}>{errors?.materialType}</span>
              </Form.Control.Feedback>
            </div>
            <div style={{ width: '30%' }}>
              <Form.Label>Vendor Type</Form.Label>
              <DropdownSelect
                name="vendorType"
                value={values?.aggregatorType}
                onChange={(e) => handleChangeValue('aggregatorType', e)}
                options={venderTypesData}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.value}
                valueKey="value"
              />
              <Form.Control.Feedback type="isInvalid">
                <span style={{ color: '#ff7272' }}>{errors?.aggregatorType}</span>
              </Form.Control.Feedback>
            </div>
          </div>
          <div style={{ width: '30%', marginRight: '10px' }}>
            <Form.Label>Select Conditions</Form.Label>
            <DropdownSelect
              name="condition"
              value={values.condition}
              onChange={(e) => handleChangeValue('condition', e, true)}
              options={conditionValues}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.value}
              isMulti
              valueKey="value"
            />
            <Form.Control.Feedback type="isInvalid">
              <span style={{ color: '#ff7272' }}>{errors?.condition}</span>
            </Form.Control.Feedback>
          </div>
          <div style={{ width: '30%', marginRight: '10px' }}>
            <Form.Label>Recalculation</Form.Label>
            <DropdownSelect
              name="recalculation"
              value={values?.intervalType}
              onChange={(e) => handleChangeValue('intervalType', e)}
              options={RECALCULATION}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.value}
              valueKey="value"
            />
            <Form.Control.Feedback type="isInvalid">
              <span style={{ color: '#ff7272' }}>{errors?.intervalType}</span>
            </Form.Control.Feedback>
          </div>
        </Form.Group>
      </div>
    </>
  );
};

export default VerticalDetail;
