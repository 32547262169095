/* eslint-disable dot-notation */
/* eslint-disable max-len */
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import axios from 'axios';
import ls from 'localstorage-slim';
import { useState } from 'react';

import { keyBy } from 'lodash';
import { userActions } from '../store/User/user.reducer';
import { authActions } from '../store/Auth/auth.reducer';
import { customerActions } from '../store/Customer/customer.reducer';
import {
  getListingItem,
  deleteLogistic,
  reActivateListings,
  deleteRecycler,
  fetchAccount,
  createPO,
  fetchLogistics,
  fetchLogisticsLogs,
  getRecyclerById,
  getUserTypeById,
  getRecyclers,
  getRecyclersPage,
  getRecyclerAppLeads,
  getRecyclersItems,
  initAppApis,
  login,
  updateLogistic,
  updateLogisticLogs,
  getAllReports,
  getAllUserInfo,
  updateRecycler,
  fetchGstin,
  fetchGstr2AData,
  gst2Areports,
  fetchGstr2APeriodData,
  fetchGstrLogData,
  fetchGstrSummaryData,
  fetchGstData,
  fetchNotesData,
  fetchReconcilationData,
  fetchReconciledData,
  fetchReconcileDocumentData,
  fetchMatchingData,
  fetchLinkData,
  fetchAcceptData,
  fetchIgnoreData,
  fetchPaymentSummary,
  downloadPaymentsReport,
  fetchReconcileButtonStatus,
  fetchPO,
  createRecycler,
  fetchQualityParams,
  fetchPOById,
  updatePO,
  getAggregators,
  getAggregatorById,
  getKamUsers,
  createKamUser,
  fetchSaleOrders,
  getSaleOrderById,
  deleteSaleOrder,
  rejectSaleOrder,
  returnSaleOrder,
  resellSaleOrder,
  saveAddress,
  updateCustomerAddress,
  updateKam,
  getDeviceDetails,
  deletePOById,
  changeKamSaleOrder,
  notesSaleOrder,
  getTransporters,
  getVehicles,
  addTruckDetails,
  updateTruckDetails,
  updateKamUser,
  assignKamCities,
  getRequisitions,
  reassignRequisition,
  updateAssessment,
  listingPriceTrend,
  deleteDocument,
  deleteItemInvoice,
  getSignedUrl,
  attachDocument,
  changeDocumentType,
  approveSaleOrder,
  sendForApproval,
  getAlternateRequisitions,
  reassignOrder,
  updateRequisition,
  assignPoToSaleOrder,
  requestImage,
  createLogistic,
  getActiveCities,
  createCity,
  getListOfCities,
  updateDuplicateCity,
  updateCity,
  getPricing,
  addWeighBridgeInfo,
  markMaterialLoaded,
  markPaymentDone,
  deletePayment,
  markDispatch,
  updateAccount,
  changePassword,
  gstinRemainder,
  bankRemainder,
  markReceivedByRecycler,
  rejectedQty,
  markQCDone,
  markEprDone,
  resetPassword,
  createPasswordFromLink,
  updatePayment,
  markAsInternalAggregator,
  updateWeighBridgeDetails,
  getRecykalMargin,
  saveRecykalMargin,
  deleteRecykalMargin,
  getCustomerNotes,
  addCustomerNote,
  getAllPermissions,
  getAllRoles,
  getUsers,
  getAllTransporters,
  getAllTransportersWithPagination,
  getTransporterById,
  updateTransporter,
  createTransporter,
  verifiedInvoiceQty,
  deleteTransporter,
  markLogisticsDocsUpload,
  adminVerificationDone,
  adminSendEmail,
  businessHeadReject,
  businessHeadApproval,
  accountsVerificationDone,
  accountsSendEmail,
  approvePO,
  openPO,
  recordPaymentPO,
  updatePaymentTerm,
  makePayment,
  transporterSaveInfo,
  paymentReceived,
  uploadJournalVoucher,
  makeGSTPayment,
  recyclerQcParams,
  debitNote,
  fetchKamListings,
  fetchKamListingsDetails,
  fetchKamListingsBuyerDetails,
  approveListings,
  rejectListings,
  updateListings,
  addEditListings,
  scheduleListings,
  getOpenPO,
  assignPO,
  disableListing,
  trackTuckById,
  addComments,
  getComments,
  getTruckDelayComments,
  fetchAvailableListings,
  fetchAvailableListingsCreatePO,
  sendSMS,
  sendDriverConsentSMS,
  endTrackingDriverTrip,
  sendConsentSMS,
  updateAggregatorAddress,
  fetchCitiesByPincode,
  deleteListingItem,
  fetchLMetrics,
  createPOAdmin,
  savePOAdmin,
  fetchCitiesByState,
  changeBuyerPrice,
  listingPriceupdate,
  rejectPO,
  getAggregatorPayTerms,
  getAggregatorByCityId,
  updateAggPaymentTerm,
  createAddRecycler,
  getRecyclerAppLeadsById,
  updateRecyclerUser,
  updatePointOfContact,
  deleteRecyclerUser,
  updateRecyclerPreferredCategory,
  updateRecyclerValidateDocuments,
  saveRecyclerPaymentTerm,
  getAllRecyclerPaymentTerms,
  saveCreditLimit,
  fetchGlobalCreditLimit,
  fetchChangeLogMetrics,
  fetchRecyclerCreditLimit,
  fetchRecyclerReceivables,
  fetchRecyclerLedger,
  fetchRecyclerInvoices,
  postBulkPayments,
  getAllTypesItemInfo,
  getAllTypes,
  createAddType,
  createAddEntity,
  createCategory,
  updateStatus,
  AddQualityParameters,
  getAllItemsLogs,
  updateitemRank,
  debitNoteSeller,
  getCustomAttributesList,
  getStandardAttributesList,
  getItemCustomAttributes,
  createCustomAttributesList,
  updateCustomAttributesList,
  createListingManually,
  updateSellerGST,
  getSellerGST,
  ListingAggregatorNoOfDays,
  getUserTypes,
  getBsUserTypeById,
  createUserType,
  updateUserType,
  getProfileUserTypes,
  getProfileUserTypeById,
  updateProfileUserType,
  toggleBuyerSellerListing,
  priceTrendSaleOrder,
  priceTrendCommentsSaleOrder,
  fetchRecyclerReceivablesv2,
  fetchRecyclerReceivablesMetrics,
  ChangeAggregatorUser,
  transporterPaymentTerm,
  getRequisitionById,
  updateRequisitionQuote,
  fetchQuotes,
  getAllRequisitions,
  createRequisition,
  splitListing,
  addnewItemSo,
  deleteItemSo,
  convertBuyerToSeller,
  deactivateRecycler,
  ConvertSellerToBuyer,
  addSellerStatus,
  getSellerStatus,
  blockOrUnblockSeller,
  getCampaignDetailsByAggregator,
  getAllLogisticItems,
  createLogisticItem,
  generateTransporterInvoice,
  AssignRequisitionToListing,
  confirmRecyclerInvoice,
  pullGSTFilingsFromAdequare,
  getGSTFilings,
  getGSTFilingsReport,
  sendDebitNote,
  modifySale,
  getAllRequisitionsAssignRequistion,
  approveNotesToSellerBuyer,
  createNotification,
  getAllNotifications,
  createNotificationRoles,
  getAllPaymentTerms,
  addPaymentTerms,
  editPaymentTerms,
  saveIncorrectDocs,
  getAllIncorrectDocs,
  deleteDocumentSaleOrder,
  createCampaign,
  createCampaignDraft,
  getAllCampaigns,
  getAllCampaignsById,
  updateCampaignStatus,
  getAllSellersByCampaignId,
  getAllCampaignsForUsers,
  getSuggestedPrice,
  getAllAggregators,
  getAllAggregatorsByName,
  updateRecykalMargin,
  saveOrUpdateETA,
  getAllTrackingMetrics,
  getTruckDriverDetailsBySaleOrder,
  fetchRecyclerReceivable,
  fetchRecyclerReceivableMetrics,
  fetchRecyclerReceivablePayments,
  fetchRecyclerReceivableInvoices,
  trackManual,
  getAllTrackingDetails,
  getAggregatorPaymentsMetrics,
  getAggregatorPaymentsList,
  getTrackingConfig,
  saveTrackingConfig,
  getTrackingConfigLogs,
  getTransporterPaymentsMetrics,
  getTransporterPaymentsList,
  getTrackingView,
  UpdatePickupAddress,
  pickupAddressLogs,
  getAllTransportersByName,
  getAggregatorDefaultScore,
  getAggregatorPendingActivities,
  getAggregatorRatingScores,
  gstManualEntry,
  getMarketplaceLogs,
  getMarketplaceLogsByCriteria,
  uploadCsv,
  updateBankDetails,
  updateAggregatorBankStatus,
  updatePreferredMaterial,
  assignTruckFromKamorApp,
  getLeads,
  getUsersByRole,
  getPOCByState,
  getUpdateSPOC,
  getPOCByRSM,
  calculateQcAmount,
  saveRecyclerQcParams,
  getRecyclerQcParams,
  googleAuthLogin,
  validateToken,
  setMarginApproval,
  getAllServiceRoles,
  getAllRolesGroup,
  createNewRole,
  editRole,
  getRoleByName,
  getAllGroups,
  getAllModules,
  getMaterialTypesList,
  getAllSystemUsers,
  getZohoItemName,
  updateItems,
  addItems,
  getItemsList,
  updateSellerStatus,
  getAllCatalogItems,
  createLoginLogoutLogs,
  getRequisitionMetrics,
  getDailyPrices,
  updateDailyPrice,
  authorizeTracking,
  updateRecyclerItem,
  getQualityParameters,
  createAggregatorOnboard,
  createAggregatorAppLeadAddress,
  updateAggregatorAppLeadAddress,
  createAggregatorAppLeadBankDetails,
  fetchAggregatorGstin,
  updateAggregatorSellerStatus,
  createListing,
  verifyVehicleDetails,
  approveRejectDocumentDetails,
  generateInvoices,
  unsubscribeMobileNumber,
  updateEwaybillNo,
  fetchGetLogistics,
  getUserActivity,
  createAggregatorUser,
  updateAggregatorUser,
  deleteAggregatorUser,
  getAggregatorUsers,
  getAllLogisticItemsWithPagination,
  getRecykalMarginQrf,
  updateSellerWeighBridgeInfo,
  updateSellerEInvoice,
  moveListingToItad,
  getAssetItemList,
  updateAggregatorAppLeadAgreementDoc,
  getRecyclerUser,
  updateRecyclerAppLeadAgreementDoc,
  getQualityParameterList,
  createPennyDropAppLeadBankDetails,
  skipAggregatorDetails,
  assignTargets,
  updateTarget,
  getAggregatorDocumentsList,
  createAggregatorOnBoarding,
  updateGSTINStatus,
  getVendorSegmentation,
  getActiveSellerTypes,
  getSegmentCondition,
  saveVendorSegmentation,
  updateVendorSegmentation,
  getSegmentLogs,
  scheduleKyc,
  getVkycDetails,
  postVKYCStatus,
  getSystemConfigsByConfigName,
  userAvailability,
  updateUserAvailability,
  getTotalUsers,
  assignSaleOrder,
  getAssignedSaleOrders,
  createAppUpdates,
  fetchAppUpdates,
  fetchUpdateDetailsByID,
  holdAssignedSaleOrder,
  approvePayment,
  getAggregatorPaymentsListByLevels,
  getSaleOrderAllocationLogs,
  getOutStandingAmount,
  readyForOnboarding,
  financeDashboard,
} from '../services/backend-api';

import { ErrorCode } from '../shared/constants';
import { catalogActions } from '../store/Catalog/catalog.reducer';
import { regionActions } from '../store/Region/region.reducer';
import { configActions } from '../store/Config/config.reducer';
import useMapper, { useCommentsMapper } from './useMapper';
import { accountsUserActions } from '../store/AccountsUser/accountUser.reducer';
import { AUTHORITY, STATIC_UNIT, TTL } from '../shared/constants/Constants';
import { documentActions } from '../store/Documents/document.reducer';
import AppAnalytics from '../services/AppAnalytics';
import useAsyncEffect from './useAsyncEffect';
import { roleActions } from '../store/Roles/role.reducer';
/**
 * API call for authentication
 * @return {({error: Error, loading: boolean, value: void}|(function(*=): Promise<void>))[]}
 */
export function useLogin() {
  const dispatch = useDispatch();
  const [{ error, loading, value }, doLogin] = useAsyncFn(async (payload) => {
    const response = await login(payload);
    dispatch(authActions.setToken(response.id_token));
    return response;
  }, []);
  return [{ error, loading, value }, doLogin];
}

export function useGoogleOAuthLogin() {
  const [
    { error: googleAuthError, loading: googleAuthLoad, value: googleAuthValue },
    doGoogleOAuthLogin,
  ] = useAsyncFn(async (params) => {
    const response = await googleAuthLogin(params);
    return response;
  }, []);

  return [
    {
      googleAuthError,
      googleAuthLoad,
      googleAuthValue,
    },
    doGoogleOAuthLogin,
  ];
}

export function useRequestResetPassword() {
  const [
    { resetPasswordError, requestPasswordLoading, requestPasswordValue },
    doResetPassword,
  ] = useAsyncFn(async (payload) => {
    const response = await resetPassword(payload);
    return response;
  }, []);

  return [
    {
      resetPasswordError,
      requestPasswordLoading,
      requestPasswordValue,
    },
    doResetPassword,
  ];
}

export function useRequestCreatePassword() {
  const [
    { createPasswordError, createPasswordLoading, createPasswordValue },
    doCreatePassword,
  ] = useAsyncFn(async (payload) => {
    const response = await createPasswordFromLink(payload);
    return response;
  });
  return [
    {
      createPasswordError,
      createPasswordLoading,
      createPasswordValue,
    },
    doCreatePassword,
  ];
}

export function useRoles() {
  const [
    { allPremissionError, allPremissionLoading, value: allPremissionValue = {} },
    doFetchAllPermission,
  ] = useAsyncFn(async (payload) => {
    const response = await getAllPermissions(payload);
    return response;
  });

  const [{ rolesError, rolesLoading, value: rolesValue = {} }, doFetchAllRoles] = useAsyncFn(
    async (payload) => {
      const response = await getAllRoles(payload);
      return response;
    }
  );

  return [
    {
      allPremissionError,
      allPremissionLoading,
      allPremissionValue,
      rolesValue,
      rolesLoading,
      rolesError,
    },
    doFetchAllPermission,
    doFetchAllRoles,
  ];
}

export function useAccount() {
  const dispatch = useDispatch();
  const { accountDetails, token, refreshToken } = useSelector(
    ({ user: { accountDetails }, auth: { token, refreshToken } }) => {
      const tokentData = token || ls?.get('userToken', { decrypt: true }) || null;
      let accountDetailsData = null;
      if (tokentData)
        accountDetailsData = accountDetails || ls.get('accountDetails', { decrypt: true });
      return {
        accountDetails: accountDetailsData,
        token: tokentData,
        refreshToken: refreshToken || ls?.get('refreshToken', { decrypt: true }) || null,
      };
    }
  );

  function logout() {
    dispatch(authActions.setToken(null));
    dispatch(userActions.setUserDetails(null));
    dispatch(userActions.setRoleNamePermissions(null));
    dispatch(authActions.setRefreshToken(null));
    ls.flush(true); // removes all data that has a ttl (i.e. even if the ttl has not expired yet)
  }

  async function updateProfile() {
    // Storing in local-storage the updated information
    const accountDetailsData = await fetchAccount();
    ls.set('accountDetails', accountDetailsData, { encrypt: true, ttl: TTL });
    ls.set('userToken', token, { encrypt: true, ttl: TTL });
    dispatch(userActions.setUserDetails(accountDetailsData));
  }

  const loading = token === undefined || null ? false : !!(accountDetails === undefined || null);

  return { accountDetails, logout, token, loading, updateProfile, refreshToken };
}

export function useAccountPassword() {
  const [
    { error: updateError, loading: updating, value: updateValue },
    doUpdateAccount,
  ] = useAsyncFn(async (payload) => {
    const response = await updateAccount(payload);
    return response;
  });

  const [{ error: getError, loading: fetching, value: getValue }, doGetAccount] = useAsyncFn(
    async () => {
      const res = await fetchAccount();
      return res;
    }
  );

  const [
    { error: passwordError, loading: password, value: passwordValue },
    doUpdatePassword,
  ] = useAsyncFn(async (payload) => {
    const res = await changePassword(payload);
    return res;
  });

  return [
    {
      updateError,
      updating,
      updateValue,
      getError,
      fetching,
      getValue,
      passwordError,
      password,
      passwordValue,
    },
    doUpdateAccount,
    doGetAccount,
    doUpdatePassword,
  ];
}

export function useInitApp() {
  const dispatch = useDispatch();
  const [{ error, loading, value }, initAppConfig] = useAsyncFn(async (payload) => {
    const response = await initAppApis();
    const catalog = response[0]?.data;
    const region = response[1]?.data;
    const Qparams = response[2]?.data;
    const businessTypes = response[3]?.data;
    const materialTypeList = response[4]?.data;
    const zonesList = response[5]?.data;
    const groupsList = response[6]?.data;
    const modulesList = response[7]?.data;
    const rolesList = response[8]?.data;
    const activeSellerTypes = response[9]?.data;
    const incorrectDocList = [
      {
        id: 1,
        docType: 'SELLER_INVOICE',
        reason: 'Sign Stamp Not Present',
      },
      {
        id: 2,
        docType: 'SELLER_INVOICE',
        reason: 'Invoice Date Incorrect',
      },
      {
        id: 3,
        docType: 'SELLER_INVOICE',
        reason: 'Description Of Goods Incorrect',
      },
      {
        id: 4,
        docType: 'SELLER_INVOICE',
        reason: 'Rate Incorrect',
      },
      {
        id: 5,
        docType: 'SELLER_INVOICE',
        reason: 'Invoice Number Incorrect',
      },
      {
        id: 6,
        docType: 'SELLER_INVOICE',
        reason: 'Rapidue GSTIN And Name Incorrect',
      },
      {
        id: 7,
        docType: 'SELLER_INVOICE',
        reason: 'Amount In Words Incorrect',
      },
      {
        id: 8,
        docType: 'SELLER_INVOICE',
        reason: 'Dispatched Address Incorrect',
      },
      {
        id: 9,
        docType: 'TRUCK_FRONT_PHOTO',
        reason: 'Vehicle Number Not Visible',
      },
      {
        id: 10,
        docType: 'TRUCK_BACK_PHOTO',
        reason: 'Vehicle Number Not Visible',
      },
      {
        id: 11,
        docType: 'EMPTY_WEIGHTBRIDGE',
        reason: 'Tare Weight Incorrect',
      },
      {
        id: 12,
        docType: 'EMPTY_WEIGHTBRIDGE',
        reason: 'Vehicle Number Not Visible',
      },
      {
        id: 13,
        docType: 'EMPTY_WEIGHTBRIDGE',
        reason: 'Date Incorrect',
      },
      {
        id: 14,
        docType: 'FULL_WEIGHBRIDGE',
        reason: 'Tare Weight Incorrect',
      },
      {
        id: 15,
        docType: 'FULL_WEIGHBRIDGE',
        reason: 'Gross Weight Incorrect',
      },
      {
        id: 16,
        docType: 'FULL_WEIGHBRIDGE',
        reason: 'Net Weight Incorrect',
      },
      {
        id: 17,
        docType: 'FULL_WEIGHBRIDGE',
        reason: 'Vehicle Number Not Visible',
      },
      {
        id: 18,
        docType: 'FULL_WEIGHBRIDGE',
        reason: 'Date Incorrect',
      },
      {
        id: 19,
        docType: 'SELLER_INVOICE',
        reason: 'Other',
      },
      {
        id: 20,
        docType: 'TRUCK_FRONT_PHOTO',
        reason: 'Other',
      },
      {
        id: 21,
        docType: 'TRUCK_BACK_PHOTO',
        reason: 'Other',
      },
      {
        id: 22,
        docType: 'EMPTY_WEIGHTBRIDGE',
        reason: 'Other',
      },
      {
        id: 23,
        docType: 'FULL_WEIGHBRIDGE',
        reason: 'Other',
      },
      {
        id: 24,
        docType: 'SELLER_EWAY_BILL',
        reason: 'Ship to address Customer GSTIN/ Name incorrect',
      },
      {
        id: 25,
        docType: 'SELLER_EWAY_BILL',
        reason: 'Ship to/ Dispatch from pincode incorrect',
      },
      {
        id: 26,
        docType: 'SELLER_EWAY_BILL',
        reason: 'Two different pincodes mentioned in ship to address',
      },
      {
        id: 27,
        docType: 'SELLER_EWAY_BILL',
        reason: 'Seller invoice date incorrect',
      },
      {
        id: 28,
        docType: 'SELLER_EWAY_BILL',
        reason: 'Quantity not mentioned',
      },
      {
        id: 29,
        docType: 'SELLER_EWAY_BILL',
        reason: 'Vehicle number is incorrect',
      },
      {
        id: 30,
        docType: 'SELLER_EWAY_BILL',
        reason: 'Other',
      },
      {
        id: 31,
        docType: 'VEHICLE_DATA_ISSUE',
        reason: 'Vehicle Number Incorrect',
      },
      {
        id: 32,
        docType: 'WEIGHBRIDGE_DATA_ISSUE',
        reason: 'Weighment Address incorrect',
      },
      {
        id: 33,
        docType: 'WEIGHBRIDGE_DATA_ISSUE',
        reason: 'Quantity Incorrect',
      },
      {
        id: 34,
        docType: 'WEIGHBRIDGE_DATA_ISSUE',
        reason: 'Weighment Number Incorrect',
      },
      {
        id: 35,
        docType: 'INVOICE_DATA_ISSUE',
        reason: 'Invoice No Incorrect',
      },
      {
        id: 36,
        docType: 'INVOICE_DATA_ISSUE',
        reason: 'Quantity incorrect',
      },
      {
        id: 37,
        docType: 'INVOICE_DATA_ISSUE',
        reason: 'Price Incorrect',
      },
      {
        id: 38,
        docType: 'INVOICE_DATA_ISSUE',
        reason: 'Invoice date Incorrect',
      },
    ];

    dispatch(catalogActions.setCatalog(catalog));
    dispatch(catalogActions.setQualityParam(Qparams));
    dispatch(regionActions.setRegion(region));
    dispatch(configActions.setConfig(businessTypes));
    dispatch(documentActions.setIncorrectDocs(incorrectDocList));
    dispatch(catalogActions.setMaterialTypeList(materialTypeList));
    dispatch(regionActions.setZonesList(zonesList));
    dispatch(roleActions.setGroupsList(groupsList));
    dispatch(roleActions.setModulesList(modulesList));
    dispatch(roleActions.setRoleList(rolesList));
    dispatch(catalogActions.setActiveSellerTypes(activeSellerTypes));

    return response;
  }, []);
  return [{ error, loading, value }, initAppConfig];
}

/**
 * Document Hooks
 * @return Array
 */
export function useDocument() {
  const [{ error: signedError, loading: singing, value: singedValue }, doGetSignedUrl] = useAsyncFn(
    async (id) => {
      const response = await getSignedUrl(id);

      return response;
    }
  );
  const [
    { error: deleteError, loading: deleting, value: deleteValue },
    doDeleteDocument,
  ] = useAsyncFn(async (id, payload) => {
    const response = payload
      ? await deleteDocumentSaleOrder(id, payload)
      : await deleteDocument(id, payload);
    return response;
  });

  const [
    { error: deleteErrorInvoice, loading: deletingInvoice, value: deleteInvoiceValue },
    doDeleteItemInvoice,
  ] = useAsyncFn(async (id) => {
    const response = await deleteItemInvoice(id);
    return response;
  });

  const [
    { error: attachError, loading: attaching, value: attachValue },
    doAttachDocument,
  ] = useAsyncFn(async (id) => {
    const response = await attachDocument(id);
    return response;
  });

  const [
    { error: changeDocError, loading: changingDoc, value: changeDocValue },
    doChangeDocType,
  ] = useAsyncFn(async (payload) => {
    const response = await changeDocumentType(payload);
    return response;
  });

  return [
    {
      changeDocError,
      changingDoc,
      changeDocValue,
      deleteError,
      deleteErrorInvoice,
      deletingInvoice,
      deleteInvoiceValue,
      deleting,
      deleteValue,
      signedError,
      singing,
      singedValue,
      attachError,
      attaching,
      attachValue,
    },
    { doDeleteDocument, doDeleteItemInvoice, doAttachDocument, doGetSignedUrl, doChangeDocType },
  ];
}

export function useLogistics() {
  const [{ error, loading, value = {} }, doFetchLogistics] = useAsyncFn(async (payload) => {
    const response = await fetchLogistics(payload);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  }, []);

  const [
    { error: fetchError, loading: fetchLoading, value: fetchValue = {} },
    doGetLogistics,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchGetLogistics(payload);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  }, []);

  // eslint-disable-next-line max-len
  const [{ error: getError, loading: getLog, value: getValue }, doFetchLogisticsLogs] = useAsyncFn(
    async (payload) => {
      const response = await fetchLogisticsLogs(payload);
      const totalCount = Number(response?.headers['x-total-count']);
      const data = response?.data;
      return { data, totalCount };
    },
    []
  );

  const [
    { error: updateError, loading: updating, value: updateValue },
    doUpdateLogistic,
  ] = useAsyncFn(async (payload) => {
    const response = await updateLogistic(payload);
    return response;
  }, []);

  const [
    { error: updateLogsError, loading: updatingLog, value: updateLogs },
    doUpdateLogisticLogs,
  ] = useAsyncFn(async (payload) => {
    const response = await updateLogisticLogs(payload);
    return response;
  }, []);

  const [
    { error: createError, loading: creating, value: createValue },
    doCreateLogistic,
  ] = useAsyncFn(async (payload) => {
    const response = await createLogistic(payload);
    return response;
  }, []);

  const [
    { error: deleteError, loading: deleting, value: deleteValue },
    doDeleteLogistic,
  ] = useAsyncFn(async (ids) => {
    const response = await deleteLogistic(ids);
    return response;
  }, []);
  return [
    {
      error,
      loading,
      value,
      updateError,
      updating,
      updateValue,
      getLog,
      updatingLog,
      updateLogsError,
      getError,
      deleteError,
      deleting,
      deleteValue,
      createError,
      creating,
      createValue,
      getValue,
      updateLogs,
      fetchError,
      fetchLoading,
      fetchValue,
    },
    {
      doFetchLogistics,
      doGetLogistics,
      doFetchLogisticsLogs,
      doUpdateLogistic,
      doUpdateLogisticLogs,
      doDeleteLogistic,
      doCreateLogistic,
    },
  ];
}

export function useRecyclers() {
  const dispatch = useDispatch();
  const [{ error, loading, value = {} }, doFetchRecycler] = useAsyncFn(async (params) => {
    const response = await getRecyclers(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    // dispatch(customerActions.setCustomerDetails(data));
    return { data, totalCount };
  });

  const [
    { error: errorRecyclerConstant, loading: loadRecyclerConstant, value: getReyclerData },
    doFetchRecyclersConstant,
  ] = useAsyncFn(async (params) => {
    const response = await getRecyclers(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: errorRecyclerAppLeads, loading: loadRecyclerAppLeads, value: getRecAppLeads },
    doFetchRecyclerAppLeads,
  ] = useAsyncFn(async (params) => {
    const response = await getRecyclerAppLeads(params);
    const totalLeadsCount = Number(response?.headers['x-total-count']);
    const leads = response?.data?.content;
    return { leads, totalLeadsCount };
  });

  const [
    { error: getPError, loading: fetchingP, value: getValueP },
    doFetchRecyclerByPage,
  ] = useAsyncFn(async (params) => {
    const response = await getRecyclersPage(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    dispatch(customerActions.setCustomerDetails(data));
    return { data, totalCount };
  });

  const [
    { error: getPErrorByItems, loading: fetchingByItems, value: getValueByItems },
    doFetchRecyclerByItems,
  ] = useAsyncFn(async (params) => {
    const response = await getRecyclersItems(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const addressIds = response?.headers['x-address-ids'];
    const data = response?.data;
    return { data, totalCount, addressIds };
  });

  const [{ error: getError, loading: fetching, value: getValue }, doGetRecyclerById] = useAsyncFn(
    async (id) => {
      const response = await getRecyclerById(id);
      return response;
    }
  );

  const [
    {
      error: getQualityParametersError,
      loading: getQualityParametersLOading,
      value: getQualityParametersValue,
    },
    doGetQualityParameters,
  ] = useAsyncFn(async (params) => {
    const response = await getQualityParameters(params);
    return response;
  });

  const [
    { error: getErrorType, loading: fetchingType, value: getUserType },
    doGetUserTypeById,
  ] = useAsyncFn(async (id) => {
    const response = await getUserTypeById(id);
    return response;
  });

  const [
    { error: getAppLeadIdError, loading: fetchingGetAppLeadId, value: getAppLeadIdValue },
    doGetRecyclerAppLeadsById,
  ] = useAsyncFn(async (param) => {
    const response = await getRecyclerAppLeadsById(param);
    return response;
  });

  const [
    { error: updateError, loading: updating, value: updateValue },
    doUpdateRecycler,
  ] = useAsyncFn(async (payload) => {
    const response = await updateRecycler(payload);
    return response;
  });

  const [
    { error: deleteError, loading: deleting, value: deleteValue },
    doDeleteRecycler,
  ] = useAsyncFn(async (id) => {
    const response = await deleteRecycler(id);
    return response;
  });

  const [
    { error: updateItemError, loading: updateItemLoading, value: updateItemValue },
    doUpdateRecyclerItem,
  ] = useAsyncFn(async (payload) => {
    const response = await updateRecyclerItem(payload);
    return response;
  });

  const [
    { error: convertBSError, loading: loadingBS, value: BSValue },
    doConvertBuyerToSeller,
  ] = useAsyncFn(async (payload) => {
    const response = await convertBuyerToSeller(payload);
    return response;
  });

  const [
    { error: convertSBError, loading: loadingSB, value: SBValue },
    doConvertSellerToBuyer,
  ] = useAsyncFn(async (payload) => {
    const response = await ConvertSellerToBuyer(payload);
    return response;
  });

  const [
    { error: createError, loading: creating, value: createValue },
    doCreateRecycler,
  ] = useAsyncFn(async (payload, appCode) => {
    const response = await createRecycler(payload, appCode);
    return response;
  });

  const [
    { error: createAddError, loading: creatingAdd, value: createAddValue },
    doCreateAddRecycler,
  ] = useAsyncFn(async (payload) => {
    const response = await createAddRecycler(payload);
    return response;
  });

  const [
    { error: getUserError, loading: getUserErr, value: getUserValue },
    doGetRecyclerUser,
  ] = useAsyncFn(async (params) => {
    const response = await getRecyclerUser(params);
    return response;
  });

  const [
    { error: updateUserError, loading: updateRecyclerLoad, value: updateRecyclerValue },
    doupdateRecyclerUser,
  ] = useAsyncFn(async (payload) => {
    const response = await updateRecyclerUser(payload);
    return response;
  });

  const [
    {
      error: updatePointOfContactError,
      loading: updatePointOfContactLoad,
      value: updatePointOfContactValue,
    },
    doupdatePointOfContact,
  ] = useAsyncFn(async (params) => {
    const response = await updatePointOfContact({ params });
    return response;
  });

  const [
    { error: deleteUserError, loading: deleteRecyclerLoad, value: deleteRecyclerValue },
    doDeleteRecyclerUser,
  ] = useAsyncFn(async (payload) => {
    const response = await deleteRecyclerUser(payload);
    return response;
  });

  const [
    {
      error: deactivateRecyclerError,
      loading: deactivateRecyclerLoading,
      value: deactivateRecyclerValue,
    },
    doDeactivateRecycler,
  ] = useAsyncFn(async (params) => {
    const response = await deactivateRecycler(params);
    return response;
  });

  const [
    {
      error: updateRecyclerPreferredCategoryError,
      loading: updateRecyclerPreferredCategoryLoading,
      value: updateRecyclerPreferredCategoryValue,
    },
    doUpdateRecyclerPreferredCategory,
  ] = useAsyncFn(async (params) => {
    const response = await updateRecyclerPreferredCategory(params);
    return response;
  });

  const [
    {
      error: updateRecyclerValidationDocError,
      loading: updateRecyclerValidationDocLoading,
      value: updateRecyclerValidationDocValue,
    },
    doUpdateRecyclerValidateDocuments,
  ] = useAsyncFn(async (params) => {
    const response = await updateRecyclerValidateDocuments(params);
    return response;
  });

  const [
    {
      error: recyclerAppLeadAgreementDocError,
      loading: recyclerAppLeadAgreementDocLoading,
      value: recyclerAppLeadAgreementDocValue,
    },

    doUpdateRecyclerAppLeadAgreementDoc,
  ] = useAsyncFn(async (payload) => {
    const response = await updateRecyclerAppLeadAgreementDoc(payload);

    return response;
  });

  const [
    {
      error: recyclerSpecificRequirementsError,
      loading: recyclerSpecificRequirementsLoading,
      value: recyclerSpecificRequirementsValue,
    },
    getRecyclerSpecificRequirements,
  ] = useAsyncFn(async (payload) => {
    const response = await getSystemConfigsByConfigName(payload);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
      getError,
      fetching,
      getValue,
      updateError,
      updating,
      updateValue,
      deleteError,
      deleting,
      deleteValue,
      createError,
      creating,
      createValue,
      createAddError,
      creatingAdd,
      createAddValue,
      updateUserError,
      updateRecyclerLoad,
      updateRecyclerValue,
      updatePointOfContactError,
      updatePointOfContactLoad,
      updatePointOfContactValue,
      deleteUserError,
      deleteRecyclerLoad,
      deleteRecyclerValue,
      getPError,
      fetchingP,
      fetchingByItems,
      getValueP,
      getErrorType,
      fetchingType,
      getUserType,
      convertBSError,
      loadingBS,
      BSValue,
      convertSBError,
      loadingSB,
      SBValue,
      errorRecyclerConstant,
      loadRecyclerConstant,
      getReyclerData,
      deactivateRecyclerError,
      deactivateRecyclerLoading,
      updateRecyclerPreferredCategoryError,
      updateRecyclerPreferredCategoryLoading,
      updateRecyclerPreferredCategoryValue,
      deactivateRecyclerValue,
      updateItemValue,
      getQualityParametersValue,
      errorRecyclerAppLeads,
      loadRecyclerAppLeads,
      getRecAppLeads,

      updateRecyclerValidationDocError,
      updateRecyclerValidationDocLoading,
      updateRecyclerValidationDocValue,

      getAppLeadIdError,
      fetchingGetAppLeadId,
      getAppLeadIdValue,

      recyclerAppLeadAgreementDocError,
      recyclerAppLeadAgreementDocLoading,
      recyclerAppLeadAgreementDocValue,

      recyclerSpecificRequirementsError,
      recyclerSpecificRequirementsLoading,
      recyclerSpecificRequirementsValue,
    },
    {
      doFetchRecycler,
      doFetchRecyclerByPage,
      doFetchRecyclerAppLeads,
      doFetchRecyclerByItems,
      doDeleteRecycler,
      doGetRecyclerById,
      doGetUserTypeById,
      doUpdateRecycler,
      doCreateRecycler,
      doCreateAddRecycler,
      doupdateRecyclerUser,
      doGetRecyclerUser,
      doupdatePointOfContact,
      doDeleteRecyclerUser,
      doUpdateRecyclerPreferredCategory,
      doUpdateRecyclerValidateDocuments,
      doConvertBuyerToSeller,
      doConvertSellerToBuyer,
      doFetchRecyclersConstant,
      doDeactivateRecycler,
      doUpdateRecyclerItem,
      doGetQualityParameters,
      doGetRecyclerAppLeadsById,
      doUpdateRecyclerAppLeadAgreementDoc,
      getRecyclerSpecificRequirements,
    },
  ];
}

export function useGlobalBuyerSellerMapping() {
  const [{ error, loading, value = {} }, doFetchUsertypes] = useAsyncFn(async (params) => {
    const response = await getUserTypes(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [{ error: getError, loading: fetching, value: getValue }, doGetUserTypeById] = useAsyncFn(
    async (id) => {
      const response = await getBsUserTypeById(id);
      return response;
    }
  );

  const [
    { error: createError, loading: creating, value: createValue },
    doCreateUserType,
  ] = useAsyncFn(async (payload) => {
    const response = await createUserType(payload);
    return response;
  });

  const [
    { error: updateUserTypeError, loading: updatingType, value: updateUserTypeValue },
    doupdateUserType,
  ] = useAsyncFn(async (payload) => {
    const response = await updateUserType(payload);
    return response;
  });

  const [
    { error: errorBSToggle, loading: loadBSToggle, value: valueBSToggle },
    doToggleBuyerSellerListing,
  ] = useAsyncFn(async (params) => {
    const response = await toggleBuyerSellerListing(params);
    return response?.data;
  });

  return [
    {
      error,
      loading,
      value,
      getError,
      fetching,
      getValue,
      createError,
      creating,
      createValue,
      updateUserTypeError,
      updatingType,
      updateUserTypeValue,
      errorBSToggle,
      loadBSToggle,
      valueBSToggle,
    },
    {
      doFetchUsertypes,
      doGetUserTypeById,
      doCreateUserType,
      doupdateUserType,
      doToggleBuyerSellerListing,
    },
  ];
}

// Buyer seller mapping profile level //

export function useProfileBuyerSellerMapping() {
  const [
    { error: fetchError, loading: fetchingProfileTypes, value = {} },
    doFetchprofileLevelUsertypes,
  ] = useAsyncFn(async (params) => {
    const response = await getProfileUserTypes(params);
    const profileUsertotalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, profileUsertotalCount };
  });

  const [
    { error: getUserTypeError, loading: userTypefetching, value: getUserTypeValue },
    doGetProfileLevelUserTypeById,
  ] = useAsyncFn(async (id) => {
    const response = await getProfileUserTypeById(id);
    return response;
  });

  const [
    {
      error: updateProfileUserTypeError,
      loading: updatingProfileType,
      value: updateProfileUserTypeValue,
    },
    doupdateProfileLevelUserType,
  ] = useAsyncFn(async (payload) => {
    const response = await updateProfileUserType(payload);
    return response;
  });

  const [
    { error: updateAggUserTypeError, loading: updatingAggType, value: updateAggUserTypeValue },
    doChangeAggregatorUser,
  ] = useAsyncFn(async (payload) => {
    const response = await ChangeAggregatorUser(payload);
    return response;
  });

  return [
    {
      fetchError,
      fetchingProfileTypes,
      value,
      getUserTypeError,
      userTypefetching,
      getUserTypeValue,
      updateProfileUserTypeError,
      updatingProfileType,
      updateProfileUserTypeValue,
      updateAggUserTypeError,
      updatingAggType,
      updateAggUserTypeValue,
    },
    {
      doFetchprofileLevelUsertypes,
      doGetProfileLevelUserTypeById,
      doupdateProfileLevelUserType,
      doChangeAggregatorUser,
    },
  ];
}

// Campaigns hooks
export function useCampaignReport() {
  const [
    { err: errorReports, load: loadReport, value: getValueReport },
    getCampaignReports,
  ] = useAsyncFn(async (params) => {
    const response = await getAllReports(params);
    return response?.data;
  }, []);

  const [
    { err: errorUSerInfo, load: loadUser, value: getCampaign },
    getCampaignAllUserInfo,
  ] = useAsyncFn(async (params) => {
    const response = await getAllUserInfo(params);
    return response?.data;
  }, []);

  return [
    {
      errorReports,
      loadReport,
      getValueReport,
      getCampaign,
      errorUSerInfo,
      loadUser,
    },
    {
      getCampaignReports,
      getCampaignAllUserInfo,
    },
  ];
}

/**
 * ==================== LISTINGS MODULE HOOK========================
 */
export function useListings() {
  const [{ error, loading, value = {} }, doFetchKamListings] = useAsyncFn(async (params) => {
    const response = await fetchKamListings(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  }, []);
  const { items } = useSelector(({ catalog }) => catalog);

  const [
    { error: metricsError, loading: metricsLoading, value: mValue = {} },
    fetchMetrics,
  ] = useAsyncFn(async (params) => {
    const response = await fetchLMetrics(params);
    // listing containing item with only KGS is being filtered here;
    return response?.data;
  }, []);

  const [
    { error: getError, loading: fetching, value: getValue },
    doGetListingOrderById,
  ] = useAsyncFn(async (id) => {
    const response = await fetchKamListingsDetails(id);
    return response;
  }, []);

  const [
    { error: getListError, loading: fetchingList, value: getBuyerValue },
    doGetBuyerListingOrderById,
  ] = useAsyncFn(async (id) => {
    const response = await fetchKamListingsBuyerDetails(id);
    return response;
  }, []);

  const [
    { error: approveError, loading: approving, value: approveValue },
    doApproveListing,
  ] = useAsyncFn(async (id, params, payload) => {
    const response = await approveListings(id, params, payload);
    return response;
  }, []);

  const [
    { error: rejectError, loading: rejecting, value: rejectValue },
    doRejectListing,
  ] = useAsyncFn(async (id, params, payload) => {
    const response = await rejectListings(id, params, payload);
    return response;
  }, []);

  const [
    { error: reactError, loading: react, value: reactValue },
    doReActivateListing,
  ] = useAsyncFn(async (id, params, payload) => {
    const response = await reActivateListings(id, params, payload);
    return response;
  }, []);

  const [{ error: deleteError, loading: deleting, value: deleteValue }, doDeleteItem] = useAsyncFn(
    async (id) => {
      const response = await deleteListingItem(id);
      return response;
    },
    []
  );

  const [{ err: errotItm, load: loadItm, value: getValueItm }, getListingItems] = useAsyncFn(
    async (params) => {
      const response = await getListingItem(params);
      return response?.data;
    },
    []
  );

  const [{ err: errotBP, load: loadBP, value: getBP }, changeBuyerPrices] = useAsyncFn(
    async (payload) => {
      const response = await changeBuyerPrice(payload);
      return response;
    },
    []
  );

  const [
    { err: errorSplit, load: loadingSplit, value: getValueSplit },
    doSplitListing,
  ] = useAsyncFn(async (payload, primaryItemId) => {
    const response = await splitListing(payload, primaryItemId);
    return response;
  }, []);

  const [
    { error: errorCreateListing, loading: loadingCreateListing, value: getCreateListing },
    doCreateListing,
  ] = useAsyncFn(async (payload, sellerId) => {
    const response = await createListing(payload, sellerId);
    return response;
  }, []);

  const [
    { error: updateError, loading: updating, value: updateValue },
    doUpdateListing,
  ] = useAsyncFn(async (payload) => {
    const response = await updateListings(payload);
    return response;
  }, []);
  const [
    { error: addEditError, loading: addEditLoading, value: addEditValue },
    doAddEditListing,
  ] = useAsyncFn(async (payload) => {
    const response = await addEditListings(payload);
    return response;
  }, []);

  const [
    { error: updateScheduleError, loading: scheduled, value: scheduledValue },
    doScheduledListing,
  ] = useAsyncFn(async (payload) => {
    const response = await scheduleListings(payload);
    return response;
  }, []);

  const [
    { error: updatePriceError, loading: updatingPrice, value: updatePriceValue },
    doUpdateListingPrice,
  ] = useAsyncFn(async (payload) => {
    const response = await listingPriceupdate(payload);
    return response;
  }, []);

  const [
    { error: updatePriceTrendError, loading: updatingPriceTrend, value: updatePriceValueTrend },
    doUpdateListingPriceTrend,
  ] = useAsyncFn(async (payload) => {
    const response = await listingPriceTrend(payload);
    return response;
  }, []);

  const [
    { error: getOpenPOError, loading: openPOFetching, value: getOpenPOValue },
    doGetOpenPO,
  ] = useAsyncFn(async (id) => {
    const response = await getOpenPO(id);
    return response;
  }, []);

  const [
    { error: disableListingError, loading: disableListingFetching, value: disableListingValue },
    doDisableListing,
  ] = useAsyncFn(async (id, payload) => {
    const response = await disableListing(id, payload);
    return response;
  }, []);

  const [
    { error: updateOpenPOError, loading: updatePOFetching, value: updateOpenPOValue },
    doUpdateListingWithPO,
  ] = useAsyncFn(async (payload) => {
    const response = await assignPO(payload);
    return response;
  }, []);

  const [
    {
      error: getavailableListingError,
      loading: availableListFetching,
      value: getAvailableListValue,
    },
    getAvailableListings,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchAvailableListings(payload);
    return response;
  }, []);

  const [
    {
      error: getavailableListingPOError,
      loading: availableListFetchingPO,
      value: getAvailableListPOValue,
    },
    getAvailableListingsPO,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchAvailableListingsCreatePO(payload);
    const totalCountListings = Number(response?.headers['x-total-count']);
    const dataListings = response?.data;
    return { dataListings, totalCountListings };
  }, []);

  const [
    {
      error: ListingAggNoOfDaysErrror,
      loading: ListingAggNoOfDaysLoad,
      value: ListingAggNoOfDaysValue,
    },
    doListingAggregatorNoOfDays,
  ] = useAsyncFn(async (payload) => {
    const response = await ListingAggregatorNoOfDays(payload);
    return response;
  }, []);

  const [
    { error: assignPoError, loading: assignLoading, value: AssignValue },
    doAssignRequisitionToListing,
  ] = useAsyncFn(async (payload) => {
    const response = await AssignRequisitionToListing(payload);
    return response;
  }, []);

  const [
    {
      error: moveListingToItadError,
      loading: moveListingToItadLoading,
      value: moveListingToItadValue,
    },
    doMoveListingToItad,
  ] = useAsyncFn(async (payload, env) => {
    const response = await moveListingToItad(payload, env);
    return response;
  });

  const [
    { error: assetItemListError, loading: assetItemListLoading, value: assetItemListValue },
    doGetAssetItemList,
  ] = useAsyncFn(async (params) => {
    const response = await getAssetItemList(params);
    return response;
  });

  const [
    {
      error: errorCreateListingManually,
      loading: loadCreateListingManually,
      value: valueCreateListingManually,
    },
    doCreateListingManually,
  ] = useAsyncFn(async (payload) => {
    const response = await createListingManually(payload);
    return response;
  });

  // AssignRequisitionToListing
  return [
    {
      error,
      loading,
      value,
      getError,
      getListError,
      fetching,
      getValue,
      getBuyerValue,
      approveError,
      approving,
      approveValue,
      rejectError,
      rejectValue,
      rejecting,
      updateError,
      updateScheduleError,

      updating,
      addEditError,
      addEditLoading,
      addEditValue,
      scheduled,
      updateValue,
      scheduledValue,
      getOpenPOError,
      openPOFetching,
      getOpenPOValue,
      updateOpenPOError,
      updatePOFetching,
      updateOpenPOValue,
      disableListingError,
      disableListingFetching,
      updatePriceTrendError,
      updatingPriceTrend,
      updatePriceValueTrend,
      disableListingValue,
      getavailableListingError,
      availableListFetching,
      getAvailableListValue,
      getavailableListingPOError,
      availableListFetchingPO,
      getAvailableListPOValue,
      deleteError,
      deleting,
      deleteValue,
      metricsError,
      metricsLoading,
      mValue,
      reactError,
      react,
      reactValue,

      errotItm,
      loadItm,
      getValueItm,

      errotBP,
      loadBP,
      getBP,
      updatePriceError,
      updatingPrice,
      updatePriceValue,

      ListingAggNoOfDaysErrror,
      ListingAggNoOfDaysLoad,
      ListingAggNoOfDaysValue,

      errorSplit,
      loadingSplit,
      getValueSplit,

      assignPoError,
      assignLoading,
      AssignValue,

      errorCreateListing,
      loadingCreateListing,
      getCreateListing,

      moveListingToItadError,
      moveListingToItadLoading,
      moveListingToItadValue,

      assetItemListError,
      assetItemListLoading,
      assetItemListValue,

      errorCreateListingManually,
      loadCreateListingManually,
      valueCreateListingManually,
    },
    {
      doFetchKamListings,
      doGetListingOrderById,
      doGetBuyerListingOrderById,
      doApproveListing,
      doRejectListing,
      doReActivateListing,
      doUpdateListing,
      doAddEditListing,
      doGetOpenPO,
      doUpdateListingWithPO,
      doDisableListing,
      getAvailableListings,
      getAvailableListingsPO,
      doDeleteItem,
      fetchMetrics,
      getListingItems,
      changeBuyerPrices,
      doUpdateListingPrice,
      doScheduledListing,
      doListingAggregatorNoOfDays,
      doSplitListing,
      doAssignRequisitionToListing,
      doUpdateListingPriceTrend,
      doCreateListing,
      doMoveListingToItad,
      doGetAssetItemList,
      doCreateListingManually,
    },
  ];
}

export function useItemType() {
  const [
    { error: errorType, loading: loadingType, value: valueType },
    doFetchTypesItemInfo,
  ] = useAsyncFn(async (params) => {
    const response = await getAllTypesItemInfo(params);
    return response?.data;
  });

  const [
    { error: errorTypeData, loading: loadingTypeData, value: valueTypeData },
    doFetchAllTypes,
  ] = useAsyncFn(async (params) => {
    const response = await getAllTypes(params);
    return response?.data;
  });

  const [
    { error: errorTypeAdd, loading: loadingTypeAdd, value: valueTypeAdd },
    doAddTypes,
  ] = useAsyncFn(async (payload) => {
    const response = await createAddType(payload);
    return response;
  });
  const [
    { error: errorEntityAdd, loading: loadingEntityAdd, value: valueEntityAdd },
    doAddEntities,
  ] = useAsyncFn(async (payload) => {
    const response = await createAddEntity(payload);
    return response;
  });

  const [
    { error: errorCategoryAdd, loading: loadingCategoryAdd, value: valueCategoryAdd },
    doAddCategory,
  ] = useAsyncFn(async (payload) => {
    const response = await createCategory(payload);
    return response;
  });

  const [
    { errorZohoItemsName, loadingZohoItemsName, valueZohoItemsName },
    doFetchZohoItemsName,
  ] = useAsyncFn(async (params) => {
    const response = await getZohoItemName(params);
    return response?.data;
  });

  const [
    { error: errorQualityParam, loading: loadQualityParam, value: valueQualityParam },
    doQualityParam,
  ] = useAsyncFn(async (payload) => {
    const response = await AddQualityParameters(payload);
    return response;
  });
  const [
    { error: errorItemLog, loading: loadItemLog, value: valueItemLog },
    doFetchItemLog,
  ] = useAsyncFn(async (id) => {
    const response = await getAllItemsLogs(id);
    return response;
  });

  const [
    { error: errorCatalogItems, loading: loadCatalogItems, value: valueCatalogItems },
    doFetchAllCatalogItem,
  ] = useAsyncFn(async (id) => {
    const response = await getAllCatalogItems(id);
    return response;
  });

  const [
    { error: errorCustomAttribute, loading: loadCustomAttribute, value: valueCustomAttribute },
    doGetCustomAttributesList,
  ] = useAsyncFn(async (param) => {
    const response = await getCustomAttributesList(param);
    return response;
  });

  const [
    {
      error: errorStandardAttribute,
      loading: loadStandardAttribute,
      value: valueStandardAttribute,
    },
    doGetStandardAttributesList,
  ] = useAsyncFn(async (param) => {
    const response = await getStandardAttributesList(param);
    return response;
  });

  const [
    {
      error: errorQualityParameterAttribute,
      loading: loadQualityParameterAttribute,
      value: valueQualityParameterAttribute,
    },
    doGetQualityParameterList,
  ] = useAsyncFn(async (param) => {
    const response = await getQualityParameterList(param);
    return response;
  });

  const [
    {
      error: errorItemCustomAttributes,
      loading: loadItemCustomAttributes,
      value: valueItemCustomAttributes,
    },
    doGetItemCustomAttributes,
  ] = useAsyncFn(async (param) => {
    const response = await getItemCustomAttributes(param);
    return response;
  });

  const [
    {
      error: errorCreateCustomAttribute,
      loading: loadCreateCustomAttribute,
      value: valueCreateCustomAttribute,
    },
    doCreateCustomAttributesList,
  ] = useAsyncFn(async (payload) => {
    const response = await createCustomAttributesList(payload);
    return response;
  });

  const [
    {
      error: errorUpdateCustomAttribute,
      loading: loadUpdateCustomAttribute,
      value: valueUpdateCustomAttribute,
    },
    doUpdateCustomAttributesList,
  ] = useAsyncFn(async (payload) => {
    const response = await updateCustomAttributesList(payload);
    return response;
  });

  const [{ errorStatus, loadingStatus, valueStatus }, doUpdateStatus] = useAsyncFn(
    async (payload) => {
      const response = await updateStatus(payload);
      return response;
    }
  );

  const [
    { errorSellerStatus, loadingSellerStatus, valueSellerStatus },
    doUpdateSellerStatus,
  ] = useAsyncFn(async (payload) => {
    const response = await updateSellerStatus(payload);
    return response;
  });

  const [
    { error: itemRankError, loading: itemRankload, value: itemRankloadValue },
    doItemRankUpdate,
  ] = useAsyncFn(async (payload) => {
    const response = await updateitemRank(payload);
    return response;
  });

  const [
    { error: updateItemError, loading: updateItemLoad, value: updateItemValue },
    doUpdateItemsUpdate,
  ] = useAsyncFn(async (payload) => {
    const response = await updateItems(payload);
    return response;
  });

  const [
    { error: addItemError, loading: addItemLoad, value: addItemValue },
    doAddItems,
  ] = useAsyncFn(async (payload) => {
    const response = await addItems(payload);
    return response;
  });

  const [
    { error: itemListError, loading: itemListLoad, value: itemListValue },
    doGetItemList,
  ] = useAsyncFn(async (id) => {
    const response = await getItemsList(id);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  return [
    {
      errorType,
      loadingType,
      valueType,
      errorTypeData,
      loadingTypeData,
      valueTypeData,
      errorTypeAdd,
      loadingTypeAdd,
      valueTypeAdd,
      errorEntityAdd,
      loadingEntityAdd,
      valueEntityAdd,
      valueCategoryAdd,
      valueZohoItemsName,
      valueStatus,
      valueSellerStatus,
      valueQualityParam,
      itemRankloadValue,
      updateItemValue,
      addItemValue,
      itemListValue,
      valueCatalogItems,

      loadingZohoItemsName,
      loadingStatus,
      loadQualityParam,
      itemRankload,
      itemListLoad,

      errorStatus,
      errorQualityParam,
      itemRankError,
      updateItemLoad,
      addItemLoad,
      loadCatalogItems,
      loadingCategoryAdd,

      errorCustomAttribute,
      loadCustomAttribute,
      valueCustomAttribute,

      errorStandardAttribute,
      loadStandardAttribute,
      valueStandardAttribute,

      errorQualityParameterAttribute,
      loadQualityParameterAttribute,
      valueQualityParameterAttribute,

      errorItemCustomAttributes,
      loadItemCustomAttributes,
      valueItemCustomAttributes,

      errorCreateCustomAttribute,
      loadCreateCustomAttribute,
      valueCreateCustomAttribute,

      errorUpdateCustomAttribute,
      loadUpdateCustomAttribute,
      valueUpdateCustomAttribute,
    },
    {
      doFetchTypesItemInfo,
      doFetchAllTypes,
      doAddTypes,
      doAddEntities,
      doUpdateStatus,
      doUpdateSellerStatus,
      doQualityParam,
      updateitemRank,
      doItemRankUpdate,
      doFetchItemLog,
      doAddCategory,
      doFetchZohoItemsName,
      doUpdateItemsUpdate,
      doAddItems,
      doGetItemList,
      doFetchAllCatalogItem,
      doGetCustomAttributesList,
      doGetStandardAttributesList,
      doGetQualityParameterList,
      doGetItemCustomAttributes,
      doCreateCustomAttributesList,
      doUpdateCustomAttributesList,
    },
  ];
}

/**
 * ==================== TRANSPORT MODULE HOOK========================
 */

export function useTransporters() {
  const [{ error, loading, value = {} }, doFetchTransporters] = useAsyncFn(async (params) => {
    const response = await getAllTransporters(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: fetchError, loading: fetchLoading, value: fetchValue = {} },
    doFetchTransportersWithPagination,
  ] = useAsyncFn(async (params) => {
    const response = await getAllTransportersWithPagination(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: getError, loading: fetching, value: getValue },
    doFetchTransporterById,
  ] = useAsyncFn(async (id) => {
    const response = await getTransporterById(id);
    return response;
  });

  const [
    { error: updateError, loading: updating, value: updateValue },
    doUpdateTransporter,
  ] = useAsyncFn(async (payload) => {
    const response = await updateTransporter(payload);
    return response;
  });

  const [
    { error: deleteError, loading: deleting, value: deleteValue },
    doDeleteTransporter,
  ] = useAsyncFn(async (payload) => {
    const response = await deleteTransporter(payload);
    return response;
  });

  const [
    { error: createError, loading: creating, value: createValue },
    doCreateTransporter,
  ] = useAsyncFn(async (payload) => {
    const response = await createTransporter(payload);
    return response;
  });

  const [
    { error: errTransList, loading: loadTransList, value: valTransList = {} },
    doFetchTransportersByName,
  ] = useAsyncFn(async (params) => {
    const response = await getAllTransportersByName(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  return [
    {
      error,
      loading,
      value,
      fetchError,
      fetchLoading,
      fetchValue,
      getError,
      fetching,
      getValue,
      updateError,
      updating,
      updateValue,
      createError,
      creating,
      createValue,
      deleteError,
      deleting,
      deleteValue,
      errTransList,
      loadTransList,
      valTransList,
    },
    {
      doFetchTransporters,
      doFetchTransportersWithPagination,
      doFetchTransporterById,
      doUpdateTransporter,
      doCreateTransporter,
      doDeleteTransporter,
      doFetchTransportersByName,
    },
  ];
}

export function useAggregators() {
  const { aggregators } = useSelector(({ user, customer, kamUser, accountUser }) => {
    return {
      aggregators: keyBy(customer.aggregatorList, 'id'),
    };
  });
  const [
    { errorConstant, loadingConstant, value: getAggData },
    doFetchAggregatorConstant,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregators(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;

    return { data, totalCount };
  });

  const [
    { error: errorGSTINStatus, loading: loadingGSTINStatus, value: updateGSTINData },
    doUpdateGSTINStatus,
  ] = useAsyncFn(async (payload) => {
    const response = await updateGSTINStatus(payload);
    const data = response;

    return { data };
  });

  const [{ error, loading, value = {} }, doFetchAggregator] = useAsyncFn(async (params) => {
    const response = await getAggregators(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const { status = 0, data = [] } = response;
    return { data, totalCount, status };
  });

  const [{ error: getError, loading: fetching, value: getValue }, doGetAggregatorById] = useAsyncFn(
    async (id) => {
      const response = await getAggregatorById(id);
      return response;
    }
  );

  const [
    { error: getErrorAGG, loading: fetchingAGG, value: getValueAGG },
    doGetAggregatorByCityId,
  ] = useAsyncFn(async (id) => {
    const response = await getAggregatorByCityId(id);
    return response;
  });
  const [
    { error: getErrorAggDoc, loading: fetchingAggDoc, value: getValueAggDoc },
    doGetAggregatorDocumentsList,
  ] = useAsyncFn(async (id) => {
    const response = await getAggregatorDocumentsList(id);
    return response;
  });

  const [
    { error: updateAggPayError, loading: updatingAggPay, value: updateValueAggPay },
    doUpdateAggPaymentTerm,
  ] = useAsyncFn(async (payload, headers) => {
    const response = await updateAggPaymentTerm(payload, headers);
    return response;
  });

  const [
    { error: updateAggregatorError, loading: updatingAggregator, value: updateAggregator },
    doMarkAsInternalAggregator,
  ] = useAsyncFn(async (id, params) => {
    const response = await markAsInternalAggregator(id, params);
    return response;
  });

  const [
    { error: updateAggAddressError, loading: updatingAggAddress, value: updateAggVal },
    doAggregatorAddressUpdate,
  ] = useAsyncFn(async (id, params) => {
    const response = await updateAggregatorAddress(id, params);
    return response;
  });

  const [{ error: updateError, loading: updating, value: updateValue }, doUpdateKam] = useAsyncFn(
    async (payload) => {
      const response = await updateKam(payload);
      return response;
    }
  );

  const [
    { error: gstinError, loading: notify, value: gstinValue },
    doNotifyAggregator,
  ] = useAsyncFn(async (payload) => {
    const response = await gstinRemainder(payload);
    return response;
  });

  const [
    { error: bankRemainderError, loading: bankRemainderLoad, value: bankRemainderValue },
    doNotifyBankAggregator,
  ] = useAsyncFn(async (payload) => {
    const response = await bankRemainder(payload);
    return response;
  });

  const [
    { error: deviceError, loading: deviceLoading, value: deviceValue },
    doGetDeviceDetails,
  ] = useAsyncFn(async (params) => {
    const response = await getDeviceDetails(params);
    return response;
  });

  const [{ value: notes }, getNotes] = useAsyncFn((id) => getCustomerNotes(id), []);

  const [{ _ }, addNote] = useAsyncFn((id, text) => addCustomerNote(id, text), []);

  const [
    { error: addingSellerError, loading: loadingSeller, value: sellerValue },
    doaddSellerStatus,
  ] = useAsyncFn(async (payload) => {
    const response = await addSellerStatus(payload);
    return response;
  });

  const [
    { error: getSellerStatusError, loading: loadingSellerStatus, value: sellerStatusValue },
    dogetSellerStatus,
  ] = useAsyncFn(async (payload) => {
    const response = await getSellerStatus(payload);
    return response;
  });

  const [
    { error: deactivateError, loading: loadingDeactivate, value: deactivateValue },
    doBlockOrUnblockSeller,
  ] = useAsyncFn(async (params) => {
    const response = await blockOrUnblockSeller(params);
    return response;
  });

  const [
    {
      error: aggregatorCampaignsError,
      loading: aggregatorCampaignsLoading,
      value: aggregatorCampaignsValue,
    },
    doGetAggregatorCampaigns,
  ] = useAsyncFn(async (params) => {
    const response = await getCampaignDetailsByAggregator(params);
    return response;
  });

  const [
    { error: updateAggError, loading: updateAggLoading, value: updateAggValue },
    doUpdateAggregatorBankStatus,
  ] = useAsyncFn(async (id, documentStatus, payload) => {
    const response = await updateAggregatorBankStatus(id, documentStatus, payload);
    return response;
  });

  const [
    {
      error: updatePreferredMaterialError,
      loading: updatePreferredMaterialLoading,
      value: updatePreferredMaterialValue,
    },
    doUpdatePreferredMaterial,
  ] = useAsyncFn(async (param) => {
    const response = await updatePreferredMaterial(param);
    return response;
  }, []);

  const [
    {
      error: aggregatordefauldScoreError,
      loading: aggregatordefauldScoreErrorLoading,
      value: aggregatordefauldScoreValue,
    },
    doGetAggregatorDefaultScore,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregatorDefaultScore(params);
    return response;
  });

  const [
    {
      error: ratingPendingActivitiesError,
      loading: ratingPendingActivitiesLoading,
      value: ratingPendingActivitiesValue,
    },
    doGetAggregatorRatingPendingActivities,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregatorPendingActivities(params);
    return response;
  });

  const [
    { error: ratingScoresError, loading: ratingScoresLoading, value: ratingScoresValue },
    doGetAggregatorRatingScores,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregatorRatingScores(params);
    return response;
  });

  const [
    { error: updateGstScoreError, loading: updatingGstScoreLoading, value: updateGstScore },
    doUpdateGstScore,
  ] = useAsyncFn(async (payload) => {
    const response = await gstManualEntry(payload);
    return response;
  });

  const [{ error: uploadError, loading: uploading, value: uploadValue }, doUploadCsv] = useAsyncFn(
    async (payload, headers) => {
      const res = await uploadCsv(payload, headers);
      return res;
    }
  );

  const [{ error: leadsError, loading: leadsLoading, value: leadsValue }, doGetLeads] = useAsyncFn(
    async (params) => {
      const response = await getLeads(params);
      return response;
    }
  );

  const [
    {
      error: aggregatorOnBoardError,
      loading: aggregatorOnBoardLoading,
      value: aggregatorOnBoardValue,
    },
    doCreateAggregatorOnBoard,
  ] = useAsyncFn(async (payload) => {
    const response = await createAggregatorOnboard(payload);
    return response;
  });

  const [
    {
      error: aggregatorAppLeadAddressError,
      loading: aggregatorAppLeadAddressLoading,
      value: aggregatorAppLeadAddressValue,
    },
    doCreateAggregatorAppLeadAddress,
  ] = useAsyncFn(async (payload) => {
    const response = await createAggregatorAppLeadAddress(payload);
    return response;
  });

  const [
    {
      error: updateAppLeadAddressError,
      loading: updateAppLeadAddressLoading,
      value: updateAppLeadAddressValue,
    },
    doUpdateAggregatorAppLeadAddress,
  ] = useAsyncFn(async (payload) => {
    const response = await updateAggregatorAppLeadAddress(payload);
    return response;
  });

  const [
    {
      error: aggregatorAppLeadBankDetailsError,
      loading: aggregatorAppLeadBankDetailsLoading,
      value: aggregatorAppLeadBankDetailsValue,
    },
    doCreateAggregatorAppLeadBankDetails,
  ] = useAsyncFn(async (payload) => {
    const response = await createAggregatorAppLeadBankDetails(payload);
    return response;
  });

  const [
    { error: pennyDropError, loading: pennyDropLoading, value: pennyDropValue },
    doCreatePennDrop,
  ] = useAsyncFn(async (payload) => {
    const response = await createPennyDropAppLeadBankDetails(payload);
    return response;
  });

  const [
    { error: aggregatorUserError, loading: aggregatorUserLoading, value: aggregatorUserValue },
    doCreateAggregatorUser,
  ] = useAsyncFn(async (payload) => {
    const response = await createAggregatorUser(payload);
    return response;
  });

  const [
    {
      error: aggregatorAppLeadAgreementDocError,
      loading: aggregatorAppLeadAgreementDocLoading,
      value: aggregatorAppLeadAgreementDocValue,
    },
    doUpdateAggregatorAppLeadAgreementDoc,
  ] = useAsyncFn(async (payload) => {
    const response = await updateAggregatorAppLeadAgreementDoc(payload);
    return response;
  });

  const [
    {
      error: updateAggregatorUserError,
      loading: updateAggregatorUserLoad,
      value: updateAggregatorUserValue,
    },
    doUpdateAggregatorUser,
  ] = useAsyncFn(async (payload) => {
    const response = await updateAggregatorUser(payload);
    return response;
  });

  const [
    {
      error: deleteAggregatorUserError,
      loading: deleteAggregatorUserLoad,
      value: deleteAggregatorUserValue,
    },
    doDeleteAggregatorUser,
  ] = useAsyncFn(async (payload) => {
    const response = await deleteAggregatorUser(payload);
    return response;
  });

  const [
    {
      error: getAggregatorUserError,
      loading: getAggregatorUserLoad,
      value: getAggregatorUserValue,
    },
    doGetAggregatorUsers,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregatorUsers(params);
    return response;
  });

  const [
    { error: aggrGstinError, loading: aggrGstinLoad, value: aggrGstinValue },
    doGetAggregatorGstin,
  ] = useAsyncFn(async (id) => {
    const response = await fetchAggregatorGstin(id);
    return response;
  });

  const [
    { error: aggrSellerStatusError, loading: aggrSellerStatusLoad, value: aggrSellerStatusValue },
    doUpdateAggregatorSellerStatus,
  ] = useAsyncFn(async (id) => {
    const response = await updateAggregatorSellerStatus(id);
    return response;
  });

  const [
    { error: userActivityError, loading: userActivityLoad, value: userActivityValue = {} },
    doGetUserActivity,
  ] = useAsyncFn(async (params) => {
    const response = await getUserActivity(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: sellerEInvoiceError, loading: sellerEInvoiceLoading, value: sellerEInvoiceValue },
    doUpdateSellerEInvoice,
  ] = useAsyncFn(async (payload) => {
    const response = await updateSellerEInvoice(payload);
    return response;
  });

  const [
    { error: skipAggError, loading: skipAggLoading, value: skipAggValue },
    doSkipAggDetails,
  ] = useAsyncFn(async (params) => {
    const response = await skipAggregatorDetails(params);
    return response;
  });

  const [
    {
      error: createAggregatorError,
      loading: createAggregatorLoading,
      value: createAggregatorValue,
    },
    doCreateAggregatorOnBoarding,
  ] = useAsyncFn(async (payload) => {
    const response = await createAggregatorOnBoarding(payload);
    return response;
  });

  const [
    { error: sheduleKYCDataError, loading: sheduleKYCDataLoading, value: sheduleKYCData },
    doScheduleKyc,
  ] = useAsyncFn(async (params) => {
    const response = await scheduleKyc(params);
    return response;
  });

  const [
    { error: fetchVKYCDetailsError, loading: fetchtingVKYCDetails, value: VKYCDetails },
    doFetchVKYCDetails,
  ] = useAsyncFn(async (params) => {
    const response = await getVkycDetails(params);
    return response;
  });

  const [
    { error: postVKYCStatusError, loading: postVKYCStatusloading, value: VkycStatus },
    doPostVKYCStatus,
  ] = useAsyncFn(async (params) => {
    const response = await postVKYCStatus(params);
    return response;
  });
  const [
    {
      error: postReadyForOnboardingError,
      loading: postReadyForOnboardingloading,
      value: ReadyForOnboardingValue,
    },
    doReadyForOnboarding,
  ] = useAsyncFn(async (action, params) => {
    const response = await readyForOnboarding(action, params);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
      getError,
      fetching,
      getValue,
      updateError,
      updating,
      updateValue,
      deviceError,
      deviceLoading,
      deviceValue,
      gstinError,
      notify,
      gstinValue,
      bankRemainderError,
      bankRemainderLoad,
      bankRemainderValue,
      updateAggregatorError,
      updatingAggregator,
      updateAggregator,
      updateAggAddressError,
      updatingAggAddress,
      updateAggVal,
      getErrorAGG,
      fetchingAGG,
      getValueAGG,
      updateAggPayError,
      updatingAggPay,
      updateValueAggPay,
      errorConstant,
      loadingConstant,
      getAggData,

      getErrorAggDoc,
      fetchingAggDoc,
      getValueAggDoc,

      addingSellerError,
      loadingSeller,
      sellerValue,

      getSellerStatusError,
      loadingSellerStatus,
      sellerStatusValue,

      deactivateError,
      loadingDeactivate,
      deactivateValue,

      aggregatorCampaignsError,
      aggregatorCampaignsLoading,
      aggregatorCampaignsValue,

      updateAggError,
      updateAggLoading,
      updateAggValue,

      aggregatordefauldScoreError,
      aggregatordefauldScoreErrorLoading,
      aggregatordefauldScoreValue,

      ratingPendingActivitiesError,
      ratingPendingActivitiesLoading,
      ratingPendingActivitiesValue,

      ratingScoresError,
      ratingScoresLoading,
      ratingScoresValue,

      updateGstScoreError,
      updatingGstScoreLoading,
      updateGstScore,

      uploadError,
      uploading,
      uploadValue,

      leadsError,
      leadsLoading,
      leadsValue,

      aggregatorOnBoardError,
      aggregatorOnBoardLoading,
      aggregatorOnBoardValue,

      aggrGstinError,
      aggrGstinLoad,
      aggrGstinValue,

      aggrSellerStatusError,
      aggrSellerStatusLoad,
      aggrSellerStatusValue,

      userActivityError,
      userActivityLoad,
      userActivityValue,

      aggregatorUserError,
      aggregatorUserLoading,
      aggregatorUserValue,

      aggregatorAppLeadAgreementDocError,
      aggregatorAppLeadAgreementDocLoading,
      aggregatorAppLeadAgreementDocValue,

      updateAggregatorUserError,
      updateAggregatorUserLoad,
      updateAggregatorUserValue,

      deleteAggregatorUserError,
      deleteAggregatorUserLoad,
      deleteAggregatorUserValue,

      getAggregatorUserError,
      getAggregatorUserLoad,
      getAggregatorUserValue,

      sellerEInvoiceError,
      sellerEInvoiceLoading,
      sellerEInvoiceValue,

      aggregatorAppLeadAddressError,
      aggregatorAppLeadAddressLoading,
      aggregatorAppLeadAddressValue,

      updateAppLeadAddressError,
      updateAppLeadAddressLoading,
      updateAppLeadAddressValue,

      aggregatorAppLeadBankDetailsError,
      aggregatorAppLeadBankDetailsLoading,
      aggregatorAppLeadBankDetailsValue,

      pennyDropError,
      pennyDropLoading,
      pennyDropValue,

      skipAggError,
      skipAggLoading,
      skipAggValue,

      createAggregatorError,
      createAggregatorLoading,
      createAggregatorValue,

      updateGSTINData,

      sheduleKYCDataError,
      sheduleKYCDataLoading,
      sheduleKYCData,

      fetchVKYCDetailsError,
      fetchtingVKYCDetails,
      VKYCDetails,

      postVKYCStatusError,
      postVKYCStatusloading,
      VkycStatus,

      postReadyForOnboardingError,
      postReadyForOnboardingloading,
      ReadyForOnboardingValue,
    },
    {
      doFetchAggregator,
      doGetAggregatorById,
      doUpdateKam,
      doGetDeviceDetails,
      doNotifyAggregator,
      doNotifyBankAggregator,
      doMarkAsInternalAggregator,
      getNotes,
      addNote,
      doAggregatorAddressUpdate,
      doGetAggregatorByCityId,
      doGetAggregatorDocumentsList,
      doUpdateAggPaymentTerm,
      doFetchAggregatorConstant,
      doUpdateGSTINStatus,
      dogetSellerStatus,
      doaddSellerStatus,
      doBlockOrUnblockSeller,
      doGetAggregatorCampaigns,
      doUpdateAggregatorBankStatus,
      doUpdatePreferredMaterial,
      doGetAggregatorDefaultScore,
      doGetAggregatorRatingPendingActivities,
      doGetAggregatorRatingScores,
      doUpdateGstScore,
      doUploadCsv,
      doGetLeads,
      doCreateAggregatorOnBoard,
      doCreateAggregatorAppLeadAddress,
      doUpdateAggregatorAppLeadAddress,
      doCreateAggregatorAppLeadBankDetails,
      doGetAggregatorGstin,
      doUpdateAggregatorSellerStatus,
      doCreateAggregatorUser,
      doUpdateAggregatorAppLeadAgreementDoc,
      doUpdateAggregatorUser,
      doDeleteAggregatorUser,
      doGetAggregatorUsers,
      doGetUserActivity,
      doUpdateSellerEInvoice,
      doCreatePennDrop,
      doSkipAggDetails,
      doCreateAggregatorOnBoarding,
      doScheduleKyc,
      doFetchVKYCDetails,
      doPostVKYCStatus,
      doReadyForOnboarding,
    },
  ];
}

export function useGstr2AData() {
  const [
    { error: gst2AError, loading: gst2ALoading, value: gst2AValue },
    getGstr2AData,
  ] = useAsyncFn(async (id, params) => {
    const response = await fetchGstr2AData(id, params);
    return response;
  });

  const [
    { error: gst2AReportError, loading: gst2AReportLoading, value: gst2AReportValue },
    getGstr2Areports,
  ] = useAsyncFn(async (id, params) => {
    const response = await gst2Areports(id, params);
    return response;
  });

  const [
    { error: gst2APeriodError, loading: gst2APeriodLoading, value: gst2APeriodValue },
    getGstr2APeriodData,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchGstr2APeriodData(payload);
    return response;
  });

  const [
    { error: gstFetchLogError, loading: gstFetchLogLoading, value: gstFetchLogValue },
    getGstLogData,
  ] = useAsyncFn(async (params) => {
    const response = await fetchGstrLogData(params);
    return response;
  });

  const [
    { error: gstFetchSummaryError, loading: gstFetchSummaryLoading, value: gstFetchSummaryValue },
    getGstSummaryData,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchGstrSummaryData(payload);
    return response;
  });

  const [
    { error: gstFetchError, loading: gstFetchLoading, value: gstFetchValue },
    getGstData,
  ] = useAsyncFn(async (params) => {
    const response = await fetchGstData(params);
    return response;
  });

  const [
    { error: gstFetchNotesError, loading: gstFetchNotesLoading, value: gstFetchNotesValue },
    getGstNotesData,
  ] = useAsyncFn(async (params) => {
    const response = await fetchNotesData(params);
    return response;
  });

  const [
    {
      error: reconcilationSummaryError,
      loading: reconcilationSummaryLoading,
      value: reconcilationSummaryValue,
    },
    getReconcilationSummaryData,
  ] = useAsyncFn(async (id, params) => {
    const response = await fetchReconcilationData(id, params);

    return response;
  });

  const [
    { error: reconciledError, loading: reconciledLoading, value: reconciledValue },
    getReconciledData,
  ] = useAsyncFn(async (params) => {
    const response = await fetchReconciledData(params);
    return response;
  });

  const [
    {
      error: reconciledDocumentError,
      loading: reconciledDocumentLoading,
      value: reconciledDocumentValue,
    },
    getReconciledDocumentData,
  ] = useAsyncFn(async (params) => {
    const response = fetchReconcileDocumentData(params);
    return response;
  });

  const [
    { error: matchingError, loading: matchingLoading, value: matchingValue },
    getMatchingData,
  ] = useAsyncFn(async (param1, param2, params) => {
    const response = await fetchMatchingData(param1, param2, params);
    return response;
  });

  const [
    { error: fetchLinkError, loading: fetchLinkLoading, value: fetchLinkValue },
    getFetchLinkData,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchLinkData(payload);
    return response;
  });

  const [
    { error: fetchAcceptError, loading: fetchAcceptLoading, value: fetchAcceptValue },
    getFetchAcceptData,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchAcceptData(payload);
    return response;
  });

  const [
    { error: fetchIgnoreError, loading: fetchIgnoreLoading, value: fetchIgnoreValue },
    getFetchIgnoreData,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchIgnoreData(payload);
    return response;
  });

  const [
    { error: paymentSummaryError, loading: paymentSummaryLoading, value: paymentSummaryValue },
    getPaymentSummary,
  ] = useAsyncFn(async (params) => {
    const response = await fetchPaymentSummary(params);
    return response;
  });

  const [
    { error: paymentReportError, loading: paymentReportLoadung, value: paymentReportValue },
    getPaymentsReport,
  ] = useAsyncFn(async (param) => {
    const response = await downloadPaymentsReport(param);
    return response;
  });

  const [
    { error: reconcileButtonError, loading: reconcileButtonLoading, value: reconcileButtonValue },
    getReconcileButtonStatus,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchReconcileButtonStatus(payload);
    return response;
  });

  return [
    {
      gst2AError,
      gst2ALoading,
      gst2AValue,
      gstFetchLogError,
      gstFetchLogLoading,
      gstFetchLogValue,
      gstFetchSummaryError,
      gstFetchSummaryLoading,
      gstFetchSummaryValue,
      reconcileButtonError,
      reconcileButtonLoading,
      reconcileButtonValue,
      gstFetchError,
      gstFetchLoading,
      gstFetchValue,
      gstFetchNotesError,
      gstFetchNotesLoading,
      gstFetchNotesValue,
      gst2APeriodError,
      gst2APeriodLoading,
      gst2APeriodValue,
      reconcilationSummaryError,
      reconcilationSummaryLoading,
      reconcilationSummaryValue,
      reconciledError,
      reconciledLoading,
      reconciledValue,
      reconciledDocumentError,
      reconciledDocumentLoading,
      reconciledDocumentValue,
      matchingError,
      matchingLoading,
      matchingValue,
      fetchLinkError,
      fetchLinkLoading,
      fetchLinkValue,
      fetchAcceptError,
      fetchAcceptLoading,
      fetchAcceptValue,
      fetchIgnoreError,
      fetchIgnoreLoading,
      fetchIgnoreValue,
      paymentSummaryError,
      paymentSummaryLoading,
      paymentSummaryValue,
    },
    {
      getGstr2AData,
      getGstr2Areports,
      getGstLogData,
      getGstSummaryData,
      getGstData,
      getGstNotesData,
      getGstr2APeriodData,
      getReconcilationSummaryData,
      getReconciledData,
      getReconciledDocumentData,
      getMatchingData,
      getFetchLinkData,
      getFetchAcceptData,
      getFetchIgnoreData,
      getPaymentSummary,
      getPaymentsReport,
      getReconcileButtonStatus,
    },
  ];
}
export function useGstin() {
  const [{ error: gstinError, loading: gstinLoading, value: gstinValue }, getGstin] = useAsyncFn(
    async (id) => {
      const response = await fetchGstin(id);
      return response;
    }
  );
  return [{ gstinError, gstinLoading, gstinValue }, getGstin];
}

export function usePinCode() {
  const [{ error, loading, value }, lookupCityByPincode] = useAsyncFn(async (pinCode) => {
    const response = await fetchCitiesByPincode(pinCode);
    return response;
  }, []);

  return [
    {
      error,
      loading,
      value,
    },
    lookupCityByPincode,
  ];
}

export function useQualityParams() {
  const [
    { error: paramsError, loading: paramsLoading, value: paramsValue },
    getQualityParams,
  ] = useAsyncFn(async (id) => {
    const response = await fetchQualityParams(id);
    return response;
  });
  return [{ paramsError, paramsLoading, paramsValue }, getQualityParams];
}

export function useKAM() {
  const dispatch = useDispatch();

  const [{ error: err, loading: fetching, value: val = {} }, doFetchKAM] = useAsyncFn(
    async (params) => {
      const response = await getKamUsers(params);
      const totalCount = Number(response?.headers['x-total-count']);
      const data = response?.data;
      // temp once api gives enitre res put condition params === undefined
      // remove params size = 1000 from maincontent.js
      return { data, totalCount };
    }
  );

  const [{ error: createError, loading: creating, createValue }, doCreateKAM] = useAsyncFn(
    async (payload) => {
      const response = await createKamUser(payload);
      return response;
    }
  );

  const [{ error: updateError, loading: updating, value: updateValue }, doUpdateKAM] = useAsyncFn(
    async (payload) => {
      const response = await updateKamUser(payload);
      return response;
    }
  );

  const [
    { error: assigningError, loading: assigning, value: citiesValue },
    doAssignCities,
  ] = useAsyncFn(async (payload) => {
    const response = await assignKamCities(payload);
    return response;
  });

  return [
    {
      err,
      fetching,
      val,
      updating,
      updateValue,
      createValue,
      citiesValue,
      assigningError,
      updateError,
      createError,
    },
    doFetchKAM,
    doCreateKAM,
    doUpdateKAM,
    doAssignCities,
  ];
}

export function useUsers() {
  const dispatch = useDispatch();

  const [{ error: err, loading: fetching, value: val = {} }, doFetchUsers] = useAsyncFn(
    async (params) => {
      const response = await getUsers(params);
      const accountUsers = response?.data?.filter((res) =>
        res?.authorities?.includes(AUTHORITY.ACCOUNTS)
      );
      dispatch(accountsUserActions.setAccountUserList(accountUsers));
      return response;
    }
  );

  const [{ error: createError, loading: creating, createValue }, doCreateUser] = useAsyncFn(
    async (payload) => {
      const response = await createKamUser(payload);
      return response;
    }
  );

  const [{ error: updateError, loading: updating, value: updateValue }, doUpdateUser] = useAsyncFn(
    async (payload) => {
      const response = await updateKamUser(payload);
      return response;
    }
  );

  const [
    { error: errorGetUser, loading: loadUser, value: getUser = {} },
    doGetUsersByRole,
  ] = useAsyncFn(async (params) => {
    return getUsersByRole(params);
  });

  return [
    {
      err,
      fetching,
      val,
      updating,
      updateValue,
      createValue,
      updateError,
      createError,
      errorGetUser,
      loadUser,
      getUser,
      creating,
    },
    {
      doFetchUsers,
      doCreateUser,
      doUpdateUser,
      doGetUsersByRole,
    },
  ];
}

/**
 * ==================== REQUISITION ========================
 */

export function useRequisition() {
  const { alternateRequisitionMapper } = useMapper();
  const [
    { error: errorold, loading: loadingold, value: valueold },
    doFetchRequisitionold,
  ] = useAsyncFn(async (params) => {
    const response = await getRequisitions(params);
    return response;
  });

  const [{ error, loading, value = {} }, doFetchRequisition] = useAsyncFn(async (params) => {
    const response = await getAllRequisitions(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: errorAssignReq, loading: loadingAssignReq, value: valueAssignReq = {} },
    doFetchRequisitionAssignRequisition,
  ] = useAsyncFn(async (params) => {
    const response = await getAllRequisitionsAssignRequistion(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: errorRequisitionId, loading: loadRequisitionId, value: requisitionValue },
    doGetRequisitionById,
  ] = useAsyncFn(async (id) => {
    const response = await getRequisitionById(id);
    return response;
  });

  const [
    { error: errorCreate, loading: creatingRequisition, value: createRequisitionValue },
    doCreateRequisition,
  ] = useAsyncFn(async (payload) => {
    const response = await createRequisition(payload);
    return response;
  });

  const [
    { error: updateError, loading: updating, value: updateValue },
    doUpdateRequisition,
  ] = useAsyncFn(async (payload, old) => {
    const response = await updateRequisition(payload, old);

    return response;
  });

  const [
    { error: errorQuote, loading: loadQuote, value: valueQuote = {} },
    doFetchQuotes,
  ] = useAsyncFn(async (params) => {
    const response = await fetchQuotes(params);
    const data = response?.data;
    const totalCount = Number(response?.headers['x-total-count']) || data?.length;
    return { data, totalCount };
  });

  const [
    { error: updateErrQuote, loading: updatingQuote, value: updateQuoteValue },
    doUpdateRequisitionQuote,
  ] = useAsyncFn(async (payload) => {
    const response = await updateRequisitionQuote(payload);
    return response;
  });

  const [
    { error: alternateError, loading: alternateFetching, value: alternateValue },
    doFetchAlternateRequisitions,
  ] = useAsyncFn(async (id, items) => {
    let response = await getAlternateRequisitions(id);
    response = alternateRequisitionMapper(response, items);
    return response;
  });

  const [
    { error: reassignError, loading: reassignLoading, value: reassignValue },
    doReassignRequisition,
  ] = useAsyncFn(async (payload) => {
    const response = await reassignRequisition(payload);
    return response;
  });

  const [
    { error: reqMetricsError, loading: reqMetricsLoading, value: reqMetricsValue },
    doGetRequisitionMetrics,
  ] = useAsyncFn(async (payload) => {
    const response = await getRequisitionMetrics(payload);
    return response;
  });

  return [
    {
      errorold,
      loadingold,
      valueold,
      error,
      loading,
      value,
      errorQuote,
      loadQuote,
      valueQuote,
      errorRequisitionId,
      loadRequisitionId,
      requisitionValue,
      alternateError,
      alternateFetching,
      alternateValue,
      updateError,
      updating,
      updateValue,
      reassignError,
      reassignLoading,
      reassignValue,
      updateErrQuote,
      updatingQuote,
      updateQuoteValue,
      errorCreate,
      creatingRequisition,
      createRequisitionValue,
      errorAssignReq,
      loadingAssignReq,
      valueAssignReq,
      reqMetricsError,
      reqMetricsLoading,
      reqMetricsValue,
    },
    {
      doFetchRequisitionold,
      doFetchRequisition,
      doGetRequisitionById,
      doUpdateRequisition,
      doFetchAlternateRequisitions,
      doReassignRequisition,
      doUpdateRequisitionQuote,
      doCreateRequisition,
      doFetchQuotes,
      doFetchRequisitionAssignRequisition,
      doGetRequisitionMetrics,
    },
  ];
}

// Notifications
export function useNotifications() {
  const [{ error, loading, value = {} }, doGetAllNotifications] = useAsyncFn(async (params) => {
    const response = await getAllNotifications(params);

    const totalCount = Number(response?.headers['x-total-count']);
    const totalUnreadNotifications = Number(response?.data.totalUnreadNotifications) || 0;
    const data = response?.data?.notifications || [];
    return { data, totalCount, totalUnreadNotifications };
  });

  const [
    { error: errorCreate, loading: loadCreate, value: valueCreate },
    doCreateNotification,
  ] = useAsyncFn(async (payload) => {
    const response = await createNotification(payload);
    return response;
  });

  const [
    { error: errorCreateRoles, loading: loadCreateRoles, value: valueCreateRoles },
    doCreateNotificationRoles,
  ] = useAsyncFn(async (payload) => {
    const response = await createNotificationRoles(payload);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
      errorCreate,
      loadCreate,
      errorCreateRoles,
      loadCreateRoles,
      valueCreateRoles,
    },
    { doGetAllNotifications, doCreateNotification, doCreateNotificationRoles },
  ];
}

export function usePurchaseOrder() {
  const [{ error: errors, loading: load, value: val }, doCreatePO] = useAsyncFn(async (payload) => {
    const response = await createPO(payload);
    return response;
  });

  const [{ error: errorsPO, loading: loadPO, value: valPO }, doCreatePOAdmin] = useAsyncFn(
    async (payload) => {
      const response = await createPOAdmin(payload);
      return response;
    }
  );

  const [{ error: errorsSPO, loading: loadSPO, value: valSPO }, doSavePOAdmin] = useAsyncFn(
    async (payload) => {
      const response = await savePOAdmin(payload);
      return response;
    }
  );

  const [{ error: updateError, loading: updating, value: updateValue }, doUpdatePO] = useAsyncFn(
    async (payload) => {
      const response = await updatePO(payload);
      return response;
    }
  );

  const [
    { error: approveError, loading: approving, value: approvingValue },
    doApprovePO,
  ] = useAsyncFn(async (payload) => {
    const response = await approvePO(payload);
    return response;
  });

  const [{ error: openError, loading: openLoading, value: openValue }, doOpenPO] = useAsyncFn(
    async (payload) => {
      const response = await openPO(payload);
      return response;
    }
  );

  const [
    { error: recordPaymentError, loading: recordPaymentLoading, value: recordPaymentValue },
    doRecordPaymentPO,
  ] = useAsyncFn(async (payload) => {
    const response = await recordPaymentPO(payload);
    return response;
  });

  const [{ error, loading, value = {} }, doFetchPO] = useAsyncFn(async (params, headerParams) => {
    const response = await fetchPO(params, headerParams);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  }, []);

  const [
    { error: getError, loading: loader, value: getValue },
    doGetPurchaseOrderById,
  ] = useAsyncFn(async (id) => {
    const response = await fetchPOById(id);
    return response;
  });

  const [
    { error: deleteError, loading: deleting, value: deleteValue },
    doDeletePurchaseOrderById,
  ] = useAsyncFn(async (id) => {
    const response = await deletePOById(id);
    return response;
  });

  const [{ error: rejectError, loading: rejecting, value: rejectValue }, doRejectPo] = useAsyncFn(
    async (id, params, payload) => {
      const response = await rejectPO(id, params, payload);
      return response;
    },
    []
  );

  return [
    {
      error,
      loading,
      value,
      errors,
      load,
      val,
      errorsPO,
      loadPO,
      valPO,
      errorsSPO,
      loadSPO,
      valSPO,
      getError,
      loader,
      getValue,
      updateError,
      updating,
      updateValue,
      deleteValue,
      deleting,
      deleteError,
      approveError,
      approving,
      approvingValue,
      openError,
      openLoading,
      openValue,
      recordPaymentError,
      recordPaymentLoading,
      recordPaymentValue,
      rejectError,
      rejecting,
      rejectValue,
    },
    {
      doFetchPO,
      doCreatePO,
      doCreatePOAdmin,
      doSavePOAdmin,
      doGetPurchaseOrderById,
      doUpdatePO,
      doDeletePurchaseOrderById,
      doApprovePO,
      doOpenPO,
      doRecordPaymentPO,
      doRejectPo,
    },
  ];
}

/**
 * =================== SALE ORDER HOOK ==============
 */
export function useSaleOrder() {
  const { orderMapper, newOrderMapper } = useMapper();
  const { CommentsMapper } = useCommentsMapper();

  const [{ error, loading, value = {} }, doFetchSaleOrders] = useAsyncFn(async (params) => {
    let response = await fetchSaleOrders(params);
    response = orderMapper(response);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  }, []);

  const [{ error: getError, loading: fetching, value: getValue }, doGetSaleOrderById] = useAsyncFn(
    async (id) => {
      let response = await getSaleOrderById(id);
      response = orderMapper(response);
      return response;
    },
    []
  );

  const [
    { error: deleteError, loading: deleting, value: deleteValue },
    doDeleteSaleOrder,
  ] = useAsyncFn(async (id) => {
    const response = await deleteSaleOrder(id);
    return response;
  }, []);

  const [
    { error: rejectError, loading: rejecting, value: rejectValue },
    doRejectOrder,
  ] = useAsyncFn(async (id, reason) => {
    const response = await rejectSaleOrder(id, reason);
    return response;
  }, []);

  const [
    { error: returnError, loading: returning, value: returnValue },
    doReturnSaleOrder,
  ] = useAsyncFn(async (params) => {
    const response = await returnSaleOrder(params);
    return response;
  }, []);

  const [
    {
      error: assignTruckFromKamorAppError,
      loading: assignTruckFromKamorAppLoading,
      value: assignTruckFromKamorAppValue,
    },
    doassignTruckFromKamorApp,
  ] = useAsyncFn(async (payload) => {
    const response = await assignTruckFromKamorApp(payload);
    return response;
  }, []);

  const [
    { error: resellError, loading: reselling, value: resellValue },
    doResellSaleOrder,
  ] = useAsyncFn(async (params) => {
    const response = await resellSaleOrder(params);
    return response;
  }, []);

  const [
    { error: approveError, loading: approving, value: approveValue },
    doApproveOrder,
  ] = useAsyncFn(async (params, payload) => {
    const response = await approveSaleOrder(params, payload);
    return response;
  }, []);

  const [
    { error: aggRequestError, loading: aggRequesting, value: aggRequestValue },
    doSendForApproval,
  ] = useAsyncFn(async (payload) => {
    const response = await sendForApproval(payload);
    return response;
  }, []);

  const [
    { error: verifiedQtyError, loading: verifiedQtyLoading, value: verifiedQtyValue },
    doSendVerifyFlag,
  ] = useAsyncFn(async (id, params, payload) => {
    const response = await verifiedInvoiceQty(id, params, payload);
    return response;
  }, []);

  const [
    { error: changeKamError, loading: changingKam, value: changeKamValue },
    doChangeKamSaleOrders,
  ] = useAsyncFn(async (payload) => {
    const response = await changeKamSaleOrder(payload);
    return response;
  }, []);

  const [
    { error: addSecondItemError, loading: addingItem, value: newItemValue },
    doAddNewItemSo,
  ] = useAsyncFn(async (payload) => {
    const response = await addnewItemSo(payload);
    return response;
  }, []);

  const [
    { error: deleteItemError, loading: deletingItem, value: deleteItemValue },
    doDeleteItemSo,
  ] = useAsyncFn(async (payload) => {
    const response = await deleteItemSo(payload);
    return response;
  }, []);

  const [
    { error: weighBridgeError, loading: weighBridgeLoading, value: weighBridgeValue },
    doUpdateWeighBridgeDetails,
  ] = useAsyncFn(async (payload) => {
    const response = await updateWeighBridgeDetails(payload);
    return response;
  }, []);

  const [{ error: notesError, loading: addingNotes, value: notesValue }, doAddNotes] = useAsyncFn(
    async (payload) => {
      const response = await notesSaleOrder(payload);
      return response;
    },
    []
  );

  const [
    {
      error: adminVerificationError,
      loading: adminVerificationLoading,
      value: adminVerificationValue,
    },
    doAdminVerification,
  ] = useAsyncFn(async (id, payload) => {
    const response = await adminVerificationDone(id, payload);
    return response;
  });

  const [
    { error: adminSendEmailError, loading: adminSendEmailLoading, value: adminSendEmailValue },
    doAdminSendEmail,
  ] = useAsyncFn(async (id, payload) => {
    const response = await adminSendEmail(id, payload);
    return response;
  });
  const [
    {
      error: accountsApprovalError,
      loading: accountsApprovalLoading,
      value: accountsApprovalValue,
    },
    doAccountsApproval,
  ] = useAsyncFn(async (id, payload) => {
    const response = await accountsVerificationDone(id, payload);
    return response;
  });

  const [
    {
      error: addTransportInfoError,
      loading: addTransportInfoLoading,
      value: addTransportInfoValue,
    },
    doAddTransportInfo,
  ] = useAsyncFn(async (payload) => {
    const response = await transporterSaveInfo(payload);
    return response;
  });

  const [
    {
      error: accountsSendEmailError,
      loading: accountsSendEmailLoading,
      value: accountsSendEmailValue,
    },
    doAccountsSendMail,
  ] = useAsyncFn(async (id, payload) => {
    const response = await accountsSendEmail(id, payload);
    return response;
  });

  const [
    {
      error: businessHeadApprovalError,
      loading: businessHeadApprovalLoading,
      value: businessHeadApprovalValue,
    },
    doBusinessHeadApproval,
  ] = useAsyncFn(async (id, payload, listingId) => {
    const response = await businessHeadApproval(id, payload, listingId);
    return response;
  });

  const [
    {
      error: businessHeadRejectError,
      loading: businessHeadRejectLoading,
      value: businessHeadRejectValue,
    },
    doBusinessHeadReject,
  ] = useAsyncFn(async (id, payload) => {
    const response = await businessHeadReject(id, payload);
    return response;
  });

  const [
    { error: transporterError, loading: transporterFetching, value: transporterValue },
    doGetTransporters,
  ] = useAsyncFn(async () => {
    const response = await getTransporters();
    return response;
  }, []);

  const [
    { error: vehicleError, loading: vechicleFetching, value: vehicleValue },
    doGetVehicles,
  ] = useAsyncFn(async () => {
    const response = await getVehicles();
    return response;
  }, []);

  const [
    { error: commentsError, loading: commentsFetching, value: commentsValue },
    doGetComments,
  ] = useAsyncFn(async (id) => {
    const response = await getComments(id);

    return CommentsMapper(response);
  }, []);

  const [
    { error: truckCommentsError, loading: truckCommentsFetching, value: truckCommentsValue },
    doGetTruckDelayComments,
  ] = useAsyncFn(async () => {
    const response = await getTruckDelayComments();

    return response;
  }, []);

  const [
    { error: addcommentError, loading: commentAdding, value: addCommentsValue },
    doAddComments,
  ] = useAsyncFn(async (payload) => {
    const response = await addComments(payload);
    return response;
  }, []);

  const [
    { error: addTruckError, loading: truckAdding, value: addTruckValue },
    doAddTruckDetails,
  ] = useAsyncFn(async (payload) => {
    const response = await addTruckDetails(payload);
    return response;
  }, []);

  const [
    { error: updateTruckError, loading: truckUpdating, value: updateTruckValue },
    doUpdateTruckDetails,
  ] = useAsyncFn(async (payload) => {
    const response = await updateTruckDetails(payload);
    return response;
  }, []);

  const [
    { error: assessUpdateError, loading: assessUpdateLoading, value: assessUpdateValue },
    doUpdateAssessment,
  ] = useAsyncFn(async (payload) => {
    const response = await updateAssessment(payload);
    return response;
  }, []);

  const [
    { error: reassignError, loading: reassigning, value: reassignValue },
    doReassignOrder,
  ] = useAsyncFn(async (payload) => {
    const response = await reassignOrder(payload);
    return response;
  }, []);

  const [
    { error: assignPoError, loading: assigningPo, value: assignPoValue },
    doAssignPurchaseOrder,
  ] = useAsyncFn(async (payload) => {
    const response = await assignPoToSaleOrder(payload);
    return response;
  }, []);

  const [
    { error: reqImgError, loading: requestingImg, value: reqImgValue },
    doRequestImage,
  ] = useAsyncFn(async (payload) => {
    const response = await requestImage(payload);
    return response;
  });

  const [
    { error: weighBrigdeError, loading: weighBrigdeLoading, value: weighBrigdeValue },
    doAddWeighBridgeInfo,
  ] = useAsyncFn(async (payload) => {
    const response = await addWeighBridgeInfo(payload);
    return response;
  });

  const [
    { error: materialLoadError, loading: materialLoading, value: materialLoadValue },
    doMarkMaterialLoaded,
  ] = useAsyncFn(async (payload) => {
    const response = await markMaterialLoaded(payload);
    return response;
  });

  const [
    {
      error: logisticsDocsUploadError,
      loading: logisticsDocsUploading,
      value: logisticsDocsUploadValue,
    },
    doMarkLogisticsDocsUpload,
  ] = useAsyncFn(async (payload) => {
    const response = await markLogisticsDocsUpload(payload);
    return response;
  });

  const [
    { error: paymentDoneError, loading: paymentDoneLoading, value: paymentDoneValue },
    doMarkPaymentDone,
  ] = useAsyncFn(async (id) => {
    const response = await markPaymentDone(id);
    return response;
  });

  const [
    { error: delPaymentError, loading: deletingPayment, value: deletePaymentValue },
    doDeletePayment,
  ] = useAsyncFn(async (payload) => {
    const response = await deletePayment(payload);
    return response;
  });

  const [
    { error: dispatchError, loading: dispatching, value: dispatchValue },
    doMarkDispatch,
  ] = useAsyncFn(async (payload) => {
    const response = await markDispatch(payload);
    return response;
  });

  const [
    { error: recRcylError, loading: recRcylLoading, value: recRcylValue },
    doMarkReceivedByRecycler,
  ] = useAsyncFn(async (payload) => {
    const response = await markReceivedByRecycler(payload);
    return response;
  });

  const [
    { error: rejectQtyError, loading: rejectingQty, value: rejectQtyValue },
    doRejectQty,
  ] = useAsyncFn(async (payload) => {
    const response = await rejectedQty(payload);
    return response;
  });

  const [{ error: qcError, loading: qcLoading, value: qcValue }, doMarkQCDone] = useAsyncFn(
    async (id, payload) => {
      const response = await markQCDone(id, payload);
      return response;
    }
  );
  const [{ error: eprError, loading: eprLoading, value: eprValue }, doMarkEPRDone] = useAsyncFn(
    async (id) => {
      const response = await markEprDone(id);
      return response;
    }
  );

  const [
    { error: qcParamsError, loading: qcParamsLoading, value: qcParamsValue },
    doRecyclerQcParams,
  ] = useAsyncFn(async (payload) => {
    const response = await recyclerQcParams(payload);
    return response;
  });

  const [
    { error: updatePayError, loading: updatePayLoading, value: updatePayValue },
    doUpdatePayment,
  ] = useAsyncFn(async (payload) => {
    const response = await updatePayment(payload);
    return response;
  });

  const [
    { error: makePaymentError, loading: makePaymentLoading, value: makePaymentValue },
    doMakePayment,
  ] = useAsyncFn(async (payload) => {
    const response = await makePayment(payload);
    return response;
  });

  const [
    { error: makeGSTPaymentError, loading: makeGSTPaymentLoading, value: makeGSTPaymentValue },
    doMakeGSTPayment,
  ] = useAsyncFn(async (payload) => {
    const response = await makeGSTPayment(payload);
    return response;
  });

  const [
    { error: receivedPaymentError, loading: receivedPaymentLoading, value: receivedPaymentValue },
    doPaymentReceived,
  ] = useAsyncFn(async (id, payload) => {
    const response = await paymentReceived(payload);
    return response;
  });

  const [
    { error: updatePaymentError, loading: updatePaymentLoading, value: updatePaymentValue },
    doUpdatePaymentTerm,
  ] = useAsyncFn(async (payload) => {
    const response = await updatePaymentTerm(payload);
    return response;
  });

  const [
    {
      error: uploadJournalVoucherError,
      loading: uploadJournalVoucherLoading,
      value: uploadJournalVoucherValue,
    },
    doUploadJournalVoucher,
  ] = useAsyncFn(async (payload) => {
    const response = await uploadJournalVoucher(payload);
    return response;
  });

  const [
    { error: debitNoteError, loading: debitNoteLoading, value: debitNoteValue },
    doDebitNote,
  ] = useAsyncFn(async (payload) => {
    const response = await debitNote(payload);
    return response;
  });

  const [
    { error: trackTuckByIdError, loading: trackTuckByIdLoading, value: trackTuckByIdValue },
    getTrackTuckById,
  ] = useAsyncFn(async (id) => {
    const response = await trackTuckById(id);
    return response;
  });

  const [
    {
      error: authorizeTrackingError,
      loading: authorizeTrackingLoading,
      value: authorizeTrackingValue,
    },
    sendTrackingAuthenticate,
  ] = useAsyncFn(async (payload, env) => {
    const response = await authorizeTracking(payload, env);
    return response;
  });

  const [
    { error: driverConsentError, loading: driverConsentLoading, value: driverConsentValue },
    sendDriverConsent,
  ] = useAsyncFn(async (payload, env) => {
    const response = await sendDriverConsentSMS(payload, env);
    return response;
  });

  const [
    { error: endDriverTripError, loading: endDriverTripLoading, value: endDriverTripValue },
    endDriverTrip,
  ] = useAsyncFn(async (payload, env) => {
    const response = await endTrackingDriverTrip(payload, env);
    return response;
  });

  const [
    { error: smsDownloadLinkError, loading: smsDownloadLinkLoading, value: smsDownloadLinkValue },
    smsDownloadLink,
  ] = useAsyncFn(async (id) => {
    const response = await sendSMS(id);
    return response;
  });

  const [
    {
      error: smsConsentDownloadLinkError,
      loading: smsConsentDownloadLinkLoading,
      value: smsConsentDownloadLinkValue,
    },
    smsConsentDownloadLink,
  ] = useAsyncFn(async (id) => {
    const response = await sendConsentSMS(id);
    return response;
  });

  const [
    { error: debitNoteSellerError, loading: debitNoteSellerLoading, value: debitNoteSellerValue },
    doDebitNoteSeller,
  ] = useAsyncFn(async (payload) => {
    delete payload.debitNoteReason;
    delete payload.debitNoteComment;
    const response = await debitNoteSeller(payload);
    return response;
  });

  const [{ loading: sendingDebitNote }, doSendDebitNote] = useAsyncFn(async (payload) => {
    const response = await sendDebitNote(payload);
    return response;
  });

  const [
    { error: priceTrendErr, loading: priceTrendLoad, value: priceTrendVal },
    doUpdatePriceTrendSaleOrder,
  ] = useAsyncFn(async (payload) => {
    const response = await priceTrendSaleOrder(payload);
    return response;
  });

  const [
    { error: sellerGSTError, loading: sellerGSTLoading, value: sellerGSTValue },
    sellerGST,
  ] = useAsyncFn(async (id) => {
    const response = await getSellerGST(id);
    return response;
  });

  const [
    { error: sellersGSTError, loading: sellersGSTLoading, value: sellersGSTValue },
    updateSellerGSTValue,
  ] = useAsyncFn(async (id) => {
    const response = await updateSellerGST(id);
  });

  const [
    { error: priceTrendCommErr, loading: priceTrendCommLoad, value: priceTrendCommVal },
    doGetPriceTrendCommentsSaleOrder,
  ] = useAsyncFn(async (id) => {
    const response = await priceTrendCommentsSaleOrder(id);
    return response;
  });

  const [
    { error: transporterPayTermErr, loading: transporterPayTermLoad, value: transporterPayTermVal },
    doTransporterPaymentTerm,
  ] = useAsyncFn(async (id, params) => {
    const response = await transporterPaymentTerm(id, params);
    return response;
  });
  const [
    { error: errApproveNotes, loading: loadApproveNotes, value: valueApproveNotes },
    doApproveNotesToSellerBuyer,
  ] = useAsyncFn(async (params) => {
    const response = await approveNotesToSellerBuyer(params);
    return response;
  });
  const [
    { error: addressError, loading: addressLoading, value: addressValue },
    doSaveAddress,
  ] = useAsyncFn(async (payload, appCode, aggrId) => {
    const response = await saveAddress(payload, appCode, aggrId);
    return response;
  }, []);

  const [
    {
      error: updateCustomerAddressError,
      loading: updateCustomerAddressLoading,
      value: updateCustomerAddressValue,
    },
    doUpdateCustomerAddress,
  ] = useAsyncFn(async (payload, appCode, aggrId) => {
    const response = await updateCustomerAddress(payload, appCode, aggrId);
    return response;
  }, []);

  const [
    { error: modifySaleError, loading: modifySaleLoading, value: modifySaleValue },
    doModifySale,
  ] = useAsyncFn(async (payload, appCode, aggrId) => {
    const response = await modifySale(payload, appCode, aggrId);
    return response;
  }, []);
  const [
    { error: errIncorrectDocs, loading: loadSaveIncorrectDocs, value: valIncorrectDocs },
    doSaveIncorrectDocs,
  ] = useAsyncFn(async (id, payload) => {
    const response = await saveIncorrectDocs(id, payload);
    return response;
  });

  const [
    { error: errGetIncorrectDocs, loading: loadGetIncorrectDocs, value: valGetAllIncorrectDocs },
    doGetAllIncorrectDocs,
  ] = useAsyncFn(async () => {
    const response = await getAllIncorrectDocs();
    return response;
  });

  const [
    { error: errTruckDriverSale, loading: loadTruckDriver, value: valGetTruckDriver },
    doGetTruckDriverDetailsBySaleOrder,
  ] = useAsyncFn(async (saleOrderId) => {
    const response = await getTruckDriverDetailsBySaleOrder(saleOrderId);
    return response;
  });

  const [
    { error: errAggPaymentsMetrics, loading: loadAggPaymentsMetrics, value: valAggPaymentsMetrics },
    doAggPaymentsMetrics,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregatorPaymentsMetrics(params);
    return response;
  });

  const [
    { error: errAggPayments, loading: loadAggPayments, value: valAggPayments = {} },
    doGetAggregatorPaymentsList,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregatorPaymentsList(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    {
      error: errAggPaymentsByLevels,
      loading: loadAggPaymentsByLevels,
      value: valAggPaymentsByLevels = {},
    },
    doGetAggregatorPaymentsListByLevels,
  ] = useAsyncFn(async (params) => {
    const response = await getAggregatorPaymentsListByLevels(params);
    const totalCountInLevels = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCountInLevels };
  });

  const [
    { error: errTransPayments, loading: loadTransPayments, value: valTransPayments = {} },
    doGetTransporterPaymentsList,
  ] = useAsyncFn(async (params) => {
    const response = await getTransporterPaymentsList(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    {
      error: errTransPaymentsMetrics,
      loading: loadTransPaymentsMetrics,
      value: valTransPaymentsMetrics,
    },
    doTransPaymentsMetrics,
  ] = useAsyncFn(async (params) => {
    const response = await getTransporterPaymentsMetrics(params);
    return response;
  });

  const [
    { error: errAddress, loading: loadAddresss, value: valPickupAddress },
    doUpdatePickupAddress,
  ] = useAsyncFn(async (id, payload) => {
    const response = await UpdatePickupAddress(id, payload);
    return response;
  });
  const [
    { error: errorAddress, loading: loadingAddress, value: valueAddress },
    doGetPickUpAddress,
  ] = useAsyncFn(async (params) => {
    const response = await pickupAddressLogs(params);
    return response;
  });

  const [
    { error: qcAmountError, loading: qcAmountLoading, value: qcAmountValue },
    doGetQcCalculation,
  ] = useAsyncFn(async (action, payload) => {
    const response = await calculateQcAmount(action, payload);
    return response;
  });

  const [
    {
      error: recyclerQcParamsError,
      loading: recyclerQcParamsLoading,
      value: recyclerQcParamsValue,
    },
    doSaveRecyclerQcParams,
  ] = useAsyncFn(async (payload) => {
    const response = await saveRecyclerQcParams(payload);
    return response;
  });

  const [
    {
      error: fetchRecyclerQcParamsError,
      loading: fetchRecyclerQcParamsLoading,
      value: fetchRecyclerQcParamsValue,
    },
    doFetchRecyclerQcParams,
  ] = useAsyncFn(async (params) => {
    const response = await getRecyclerQcParams(params);
    return response;
  });

  const [
    {
      error: verifyVehicleDetailsError,
      loading: verifyVehicleDetailsLoading,
      value: verifyVehicleDetailsValue,
    },
    doVerifyVehicleDetails,
  ] = useAsyncFn(async (payload) => {
    const response = await verifyVehicleDetails(payload);
    return response;
  }, []);

  const [
    {
      error: approveRejectDocumentsError,
      loading: approveRejectDocumentsLoading,
      value: approveRejectDocumentsValue,
    },
    doApproveRejectAggregatorDocumentDetails,
  ] = useAsyncFn(async (payload) => {
    const response = await approveRejectDocumentDetails(payload);
    return response;
  }, []);

  const [
    {
      error: generateInvoicesError,
      loading: generateInvoicesLoading,
      value: generateInvoicesValue,
    },
    doGenerateInvoices,
  ] = useAsyncFn(async (params) => {
    const response = await generateInvoices(params);
    return response;
  }, []);

  const [
    {
      error: unsubscribeMobileError,
      loading: unsubscribeMobileLoading,
      value: unsubscribeMobileValue,
    },
    doUnsubscribeMobileNumber,
  ] = useAsyncFn(async (payload) => {
    const response = await unsubscribeMobileNumber(payload);
    return response;
  });

  const [
    {
      error: weighBridgeUpdateError,
      loading: weighBridgeUpdateLoading,
      value: weighBridgeUpdateValue,
    },
    doUpdateSellerWeighBridge,
  ] = useAsyncFn(async (payload) => {
    const response = await updateSellerWeighBridgeInfo(payload);
    return response;
  });

  const [
    { error: approvePaymentError, loading: approvePaymentLoading, value: approvePaymentValue },
    doApprovePayment,
  ] = useAsyncFn(async (payload) => {
    const response = await approvePayment(payload);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
      // saleOrderCount,
      addressError,
      addressLoading,
      addressValue,
      modifySaleError,
      modifySaleLoading,
      modifySaleValue,
      getError,
      fetching,
      getValue,
      deleteError,
      deleting,
      deleteValue,
      rejectError,
      rejecting,
      rejectValue,
      returnError,
      returning,
      returnValue,
      approveError,
      approving,
      approveValue,
      aggRequestError,
      aggRequesting,
      aggRequestValue,
      changeKamError,
      changingKam,
      changeKamValue,
      notesError,
      addingNotes,
      notesValue,
      transporterError,
      sellerGSTError,
      sellersGSTError,
      transporterFetching,
      transporterValue,
      vehicleError,
      vechicleFetching,
      vehicleValue,
      trackTuckByIdError,
      addTruckError,
      truckAdding,
      addTruckValue,
      updateTruckError,
      truckUpdating,
      updateTruckValue,
      assessUpdateError,
      assessUpdateLoading,
      assessUpdateValue,
      reassignError,
      reassigning,
      reassignValue,
      assignPoError,
      assigningPo,
      assignPoValue,
      reqImgError,
      requestingImg,
      reqImgValue,
      weighBrigdeError,
      weighBrigdeLoading,
      weighBrigdeValue,
      materialLoadError,
      materialLoading,
      materialLoadValue,
      paymentDoneError,
      paymentDoneLoading,
      paymentDoneValue,
      delPaymentError,
      deletingPayment,
      deletePaymentValue,
      dispatchError,
      dispatching,
      dispatchValue,
      updatePayError,
      updatePayLoading,
      updatePayValue,
      weighBridgeError,
      weighBridgeValue,
      weighBridgeLoading,
      verifiedQtyError,
      verifiedQtyLoading,
      verifiedQtyValue,
      logisticsDocsUploadError,
      logisticsDocsUploading,
      logisticsDocsUploadValue,
      adminVerificationError,
      adminVerificationLoading,
      adminVerificationValue,
      adminSendEmailError,
      adminSendEmailLoading,
      adminSendEmailValue,
      businessHeadApprovalError,
      businessHeadApprovalLoading,
      businessHeadApprovalValue,
      businessHeadRejectError,
      businessHeadRejectValue,
      businessHeadRejectLoading,
      accountsApprovalError,
      accountsApprovalValue,
      accountsApprovalLoading,
      accountsSendEmailError,
      accountsSendEmailLoading,
      accountsSendEmailValue,
      updatePaymentError,
      updatePaymentLoading,
      sellerGSTLoading,
      sellersGSTLoading,
      updatePaymentValue,
      makePaymentError,
      makePaymentLoading,
      makePaymentValue,
      makeGSTPaymentError,
      makeGSTPaymentValue,
      makeGSTPaymentLoading,
      addTransportInfoError,
      addTransportInfoLoading,
      addTransportInfoValue,
      receivedPaymentError,
      receivedPaymentLoading,
      receivedPaymentValue,
      uploadJournalVoucherError,
      uploadJournalVoucherValue,
      uploadJournalVoucherLoading,
      qcParamsError,
      qcParamsLoading,
      qcParamsValue,
      debitNoteError,
      debitNoteLoading,
      debitNoteValue,
      debitNoteSellerError,
      debitNoteSellerLoading,
      debitNoteSellerValue,
      sendingDebitNote,
      addcommentError,
      commentAdding,
      addCommentsValue,
      commentsError,
      commentsFetching,
      commentsValue,
      truckCommentsValue,
      eprError,
      eprLoading,
      eprValue,
      trackTuckByIdLoading,
      smsDownloadLinkError,
      smsConsentDownloadLinkError,
      trackTuckByIdValue,
      smsDownloadLinkLoading,
      smsConsentDownloadLinkLoading,
      smsDownloadLinkValue,
      smsConsentDownloadLinkValue,
      driverConsentError,
      driverConsentLoading,
      driverConsentValue,
      endDriverTripError,
      endDriverTripLoading,
      endDriverTripValue,
      sellerGSTValue,
      sellersGSTValue,
      priceTrendErr,
      priceTrendLoad,
      priceTrendVal,
      priceTrendCommErr,
      priceTrendCommLoad,
      priceTrendCommVal,
      transporterPayTermErr,
      transporterPayTermLoad,
      transporterPayTermVal,
      addSecondItemError,
      addingItem,
      newItemValue,
      deleteItemError,
      deletingItem,
      deleteItemValue,
      errApproveNotes,
      loadApproveNotes,
      valueApproveNotes,

      errIncorrectDocs,
      loadSaveIncorrectDocs,
      valIncorrectDocs,

      errGetIncorrectDocs,
      loadGetIncorrectDocs,
      valGetAllIncorrectDocs,

      errTruckDriverSale,
      loadTruckDriver,
      valGetTruckDriver,

      errAggPaymentsMetrics,
      loadAggPaymentsMetrics,
      valAggPaymentsMetrics,
      errAggPayments,
      loadAggPayments,
      valAggPayments,
      errTransPayments,
      loadTransPayments,
      valTransPayments,

      loadAggPaymentsByLevels,
      valAggPaymentsByLevels,
      errAggPaymentsByLevels,

      errTransPaymentsMetrics,
      loadTransPaymentsMetrics,
      valTransPaymentsMetrics,

      errAddress,
      loadAddresss,
      valPickupAddress,
      errorAddress,
      loadingAddress,
      valueAddress,
      assignTruckFromKamorAppError,
      assignTruckFromKamorAppLoading,
      assignTruckFromKamorAppValue,
      qcAmountError,
      qcAmountLoading,
      qcAmountValue,
      recyclerQcParamsError,
      recyclerQcParamsLoading,
      recyclerQcParamsValue,
      fetchRecyclerQcParamsError,
      fetchRecyclerQcParamsLoading,
      fetchRecyclerQcParamsValue,
      authorizeTrackingError,
      authorizeTrackingLoading,
      authorizeTrackingValue,

      verifyVehicleDetailsError,
      verifyVehicleDetailsLoading,
      verifyVehicleDetailsValue,

      approveRejectDocumentsError,
      approveRejectDocumentsLoading,
      approveRejectDocumentsValue,

      unsubscribeMobileError,
      unsubscribeMobileLoading,
      unsubscribeMobileValue,

      recRcylLoading,
      qcLoading,
      reselling,

      weighBridgeUpdateLoading,
      weighBridgeUpdateError,
      weighBridgeUpdateValue,

      approvePaymentValue,
      approvePaymentLoading,
      approvePaymentError,
    },
    {
      doFetchSaleOrders,
      doGetSaleOrderById,
      doDeleteSaleOrder,
      doRejectOrder,
      doReturnSaleOrder,
      doResellSaleOrder,
      doSaveAddress,
      doUpdateCustomerAddress,
      doModifySale,
      doChangeKamSaleOrders,
      doAddNotes,
      doGetTransporters,
      doGetVehicles,
      doApproveOrder,
      doSendForApproval,
      doAddTruckDetails,
      doUpdateTruckDetails,
      doUpdateAssessment,
      doReassignOrder,
      doAssignPurchaseOrder,
      doRequestImage,
      doAddWeighBridgeInfo,
      doMarkMaterialLoaded,
      doMarkPaymentDone,
      doDeletePayment,
      doMarkDispatch,
      doMarkQCDone,
      doRejectQty,
      doMarkReceivedByRecycler,
      doUpdatePayment,
      doSendVerifyFlag,
      doUpdateWeighBridgeDetails,
      doMarkLogisticsDocsUpload,
      doAdminVerification,
      doAdminSendEmail,
      doBusinessHeadApproval,
      doBusinessHeadReject,
      doAccountsApproval,
      doAccountsSendMail,
      doUpdatePaymentTerm,
      doMakePayment,
      doAddTransportInfo,
      doPaymentReceived,
      doUploadJournalVoucher,
      doMakeGSTPayment,
      doRecyclerQcParams,
      doDebitNote,
      doDebitNoteSeller,
      doSendDebitNote,
      doAddComments,
      doGetComments,
      doGetTruckDelayComments,
      doMarkEPRDone,
      getTrackTuckById,
      smsDownloadLink,
      smsConsentDownloadLink,
      sendDriverConsent,
      endDriverTrip,
      sellerGST,
      updateSellerGSTValue,
      doUpdatePriceTrendSaleOrder,
      doGetPriceTrendCommentsSaleOrder,
      doTransporterPaymentTerm,
      doAddNewItemSo,
      doDeleteItemSo,
      doApproveNotesToSellerBuyer,
      doSaveIncorrectDocs,
      doGetAllIncorrectDocs,
      doGetTruckDriverDetailsBySaleOrder,
      doAggPaymentsMetrics,
      doGetAggregatorPaymentsList,
      doGetAggregatorPaymentsListByLevels,
      doGetTransporterPaymentsList,
      doTransPaymentsMetrics,
      doGetPickUpAddress,
      doUpdatePickupAddress,
      doassignTruckFromKamorApp,
      doGetQcCalculation,
      doSaveRecyclerQcParams,
      doFetchRecyclerQcParams,
      sendTrackingAuthenticate,
      doVerifyVehicleDetails,
      doApproveRejectAggregatorDocumentDetails,
      doGenerateInvoices,
      doUnsubscribeMobileNumber,
      doUpdateSellerWeighBridge,
      doApprovePayment,
    },
  ];
}

/**
 * =================== CITIES HOOK ==============
 */

export function useCities() {
  const [{ error, loading, value }, doFetchActiveCities] = useAsyncFn(async () => {
    const response = await getActiveCities();
    return response;
  });
  const [{ error: createError, loading: creating, value: createValue }, doCreateCity] = useAsyncFn(
    async (payload) => {
      const response = await createCity(payload);
      return response;
    }
  );

  const [
    { error: getAllCreateError, loading: getAllcreating, value: getAllcreateValue },
    doGetListOfCities,
  ] = useAsyncFn(async (params) => {
    const response = await getListOfCities(params);
    return response;
  });

  const [
    {
      error: updateDuplicateCityError,
      loading: updateDuplicateCityloading,
      value: updateDuplicateCityValue,
    },
    doUpdateDuplicateCity,
  ] = useAsyncFn(async (payload) => {
    const response = await updateDuplicateCity(payload);
    return response;
  });

  const [
    { error: AggPayTermsError, loading: pAggPayTermsLoading, value: AggPayTermsValue },
    aggPaymentTerms,
  ] = useAsyncFn(async (payload) => {
    const response = await getAggregatorPayTerms(payload);
    return response;
  });

  const [
    { error: updateError, loading: updateCityLoading, value: updateValue },
    doUpdateCity,
  ] = useAsyncFn(async (payload) => {
    const response = await updateCity(payload);
    return response;
  });

  const [
    {
      error: getavailableCitiesError,
      loading: availableCitiesFetching,
      value: getAvailableCitiesList,
    },
    getAvailableCities,
  ] = useAsyncFn(async (payload) => {
    const response = await fetchCitiesByState(payload);
    return response;
  }, []);

  return [
    {
      error,
      loading,
      value,
      createError,
      creating,
      createValue,
      updateError,
      updateCityLoading,
      updateValue,
      availableCitiesFetching,
      getAvailableCitiesList,

      AggPayTermsError,
      pAggPayTermsLoading,
      AggPayTermsValue,
    },
    {
      doFetchActiveCities,
      doGetListOfCities,
      doCreateCity,
      doUpdateDuplicateCity,
      doUpdateCity,
      getAvailableCities,

      aggPaymentTerms,
    },
  ];
}
/**
 * =================== PRICING HOOK ==============
 */

export function usePricing() {
  const [
    { error: pricingError, loading: pricingLoading, value: pricingValue },
    doGetPricing,
  ] = useAsyncFn(async (params) => {
    const response = await getPricing(params);
    return response;
  });
  return [{ pricingError, pricingLoading, pricingValue }, doGetPricing];
}

export function useRecykalMargin() {
  const [{ error, loading, value }, doFetchRecykalMargin] = useAsyncFn(async () => {
    const response = await getRecykalMargin();
    return response;
  });

  const [
    { error: saveMarginError, loading: loadingSaveMargin, value: valueMargin },
    doSaveRecykalMargin,
  ] = useAsyncFn(async (payload) => {
    const response = await saveRecykalMargin(payload);
    return response;
  });

  const [
    { error: deleteMarginError, loading: loadingdeleteMargin, value: valueDeleteMargin },
    doDeleteRecykalmargin,
  ] = useAsyncFn(async (payload) => {
    const response = await deleteRecykalMargin(payload);
    return response;
  });

  const [
    { error: updateMarginError, loading: loadingupdateMargin, value: valueUpdateMargin },
    doUpdateRecykalMargin,
  ] = useAsyncFn(async (payload) => {
    const response = await updateRecykalMargin(payload);
    return response;
  });

  const [
    { error: getMarginQrfError, loading: getMarginQrfLoading, value: getMarginQrfValue },
    doGetRecykalMarginQrf,
  ] = useAsyncFn(async (params) => {
    const response = await getRecykalMarginQrf(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  return [
    {
      error,
      loading,
      value,
      saveMarginError,
      loadingSaveMargin,
      valueMargin,
      deleteMarginError,
      loadingdeleteMargin,
      valueDeleteMargin,
      updateMarginError,
      loadingupdateMargin,
      valueUpdateMargin,
      getMarginQrfError,
      getMarginQrfLoading,
      getMarginQrfValue,
    },
    {
      doFetchRecykalMargin,
      doSaveRecykalMargin,
      doDeleteRecykalmargin,
      doUpdateRecykalMargin,
      doGetRecykalMarginQrf,
    },
  ];
}

/**
 * ==================== Recycler Payment Terms and Credit Limit Hook ========================
 */
export function useRecyclerPaymentTermsAndCreditLimit() {
  const [createRecyclerPaymentTerm, doSaveRecyclerPaymentTerm] = useAsyncFn(async (payload) => {
    const response = await saveRecyclerPaymentTerm(payload);
    return response;
  });

  const [getRecyclerPaymentTerms, doGetAllRecyclerPaymentTerms] = useAsyncFn(async (params) => {
    const response = await getAllRecyclerPaymentTerms(params);
    return response;
  });

  const [createCreditLimit, doSaveCreditLimit] = useAsyncFn(async (payload) => {
    const response = await saveCreditLimit(payload);
    return response;
  });

  const [getGlobalCreditLimit, doGetGlobalCreditLimit] = useAsyncFn(async () => {
    const response = await fetchGlobalCreditLimit();
    return response;
  });

  const [getRecyclerCreditLimit, doGetRecyclerCreditLimit] = useAsyncFn(async (params) => {
    const response = await fetchRecyclerCreditLimit(params);
    return response;
  });

  const [getChangeLogMetrics, doGetChangeLogMetrics] = useAsyncFn(async (params) => {
    const response = await fetchChangeLogMetrics(params);
    return response;
  });

  const [getReceivables, doGetReceivables] = useAsyncFn(async (params) => {
    const response = await fetchRecyclerReceivables(params);
    return response;
  });

  const [getReceivablesV2, doGetReceivablesV2] = useAsyncFn(async (params) => {
    const response = await fetchRecyclerReceivablesv2(params);
    return response;
  });

  const [getReceivablesMetrics, doGetReceivablesMetrics] = useAsyncFn(async (params) => {
    const response = await fetchRecyclerReceivablesMetrics(params);
    return response;
  });

  const [getRecyclerReceivables, doGetRecyclerReceivables] = useAsyncFn(async (params) => {
    const response = await fetchRecyclerReceivable(params);
    return response;
  });

  const [getRecyclerReceivablesMetrics, doGetRecyclerReceivablesMetrics] = useAsyncFn(
    async (params) => {
      const response = await fetchRecyclerReceivableMetrics(params);
      return response;
    }
  );

  const [getRecyclerReceivablesPayments, doGetRecyclerReceivablesPayments] = useAsyncFn(
    async (params) => {
      const response = await fetchRecyclerReceivablePayments(params);
      return response;
    }
  );

  const [getRecyclerReceivablesInvoices, doGetRecyclerReceivablesInvoices] = useAsyncFn(
    async (params) => {
      const response = await fetchRecyclerReceivableInvoices(params);
      return response;
    }
  );

  const [getRecyclerLedger, doGetRecyclerLedger] = useAsyncFn(async (params) => {
    const response = await fetchRecyclerLedger(params);
    return response;
  });

  const [getInvoices, doGetInvoices] = useAsyncFn(async (params) => {
    const response = await fetchRecyclerInvoices(params);
    return response;
  });

  const [saveBulkPayments, doPostBulkPayments] = useAsyncFn(async (payload) => {
    const response = await postBulkPayments(payload);
    return response;
  });

  return [
    {
      error:
        createRecyclerPaymentTerm.error ||
        getRecyclerPaymentTerms.error ||
        createCreditLimit.error ||
        getGlobalCreditLimit.error ||
        getChangeLogMetrics.error ||
        getRecyclerCreditLimit.error ||
        getReceivables.error ||
        getReceivablesV2.error ||
        getReceivablesMetrics.error ||
        getInvoices.error ||
        saveBulkPayments.error ||
        getRecyclerLedger.error ||
        getRecyclerReceivables.error ||
        getRecyclerReceivablesMetrics.error ||
        getRecyclerReceivablesPayments.error ||
        getRecyclerReceivablesInvoices.error,
      loading:
        createRecyclerPaymentTerm.loading ||
        getRecyclerPaymentTerms.loading ||
        createCreditLimit.loading ||
        getGlobalCreditLimit.loading ||
        getChangeLogMetrics.loading ||
        getRecyclerCreditLimit.loading ||
        getReceivables.loading ||
        getReceivablesV2.loading ||
        getReceivablesMetrics.loading ||
        getInvoices.loading ||
        saveBulkPayments.loading ||
        getRecyclerLedger.error ||
        getRecyclerReceivables.loading ||
        getRecyclerReceivablesMetrics.loading ||
        getRecyclerReceivablesPayments.loading ||
        getRecyclerReceivablesInvoices.loading,
      value:
        createRecyclerPaymentTerm.value ||
        getRecyclerPaymentTerms.value ||
        createCreditLimit.value ||
        getGlobalCreditLimit.value ||
        getChangeLogMetrics.value ||
        getRecyclerCreditLimit.value ||
        getReceivables.value ||
        getReceivablesV2.value ||
        getReceivablesMetrics.value ||
        getInvoices.value ||
        saveBulkPayments.value ||
        getRecyclerLedger.error ||
        getRecyclerReceivables.value ||
        getRecyclerReceivablesMetrics.value ||
        getRecyclerReceivablesPayments.value ||
        getRecyclerReceivablesInvoices.value,
    },
    {
      doSaveRecyclerPaymentTerm,
      doGetAllRecyclerPaymentTerms,
      doSaveCreditLimit,
      doGetGlobalCreditLimit,
      doGetChangeLogMetrics,
      doGetRecyclerCreditLimit,
      doGetReceivables,
      doGetReceivablesV2,
      doGetReceivablesMetrics,
      doGetInvoices,
      doPostBulkPayments,
      doGetRecyclerLedger,
      doGetRecyclerReceivables,
      doGetRecyclerReceivablesMetrics,
      doGetRecyclerReceivablesPayments,
      doGetRecyclerReceivablesInvoices,
    },
  ];
}

/**
 * ==================== LOGISTIC ITEM MASTER MODULE HOOK========================
 */

export function useLogisticItemMaster() {
  const [fetchLogisticItems, doFetchLogisticItems] = useAsyncFn(async (params) => {
    const response = await getAllLogisticItems(params);
    return response;
  });

  const [
    { error: logisticsError, loading: logisticsLoading, value: logisticsValue = {} },
    doGetLogisticItems,
  ] = useAsyncFn(async (params) => {
    const response = await getAllLogisticItemsWithPagination(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [saveLogisticItem, doSaveLogisticItem] = useAsyncFn(async (payload) => {
    const response = await createLogisticItem(payload);
    return response;
  });

  return [
    {
      error: fetchLogisticItems.error || saveLogisticItem.error,
      loading: fetchLogisticItems.loading || saveLogisticItem.loading,
      value: fetchLogisticItems.value || saveLogisticItem.value,
      logisticsError,
      logisticsLoading,
      logisticsValue,
    },
    {
      doFetchLogisticItems,
      doGetLogisticItems,
      doSaveLogisticItem,
    },
  ];
}

export function useZoho() {
  const [genTransporterInvoice, doGenerateTransporterInvoice] = useAsyncFn(async (params) => {
    const response = await generateTransporterInvoice(params);
    return response;
  });

  const [getConfirmInvoice, doConfirmInvoice] = useAsyncFn(async (params) => {
    const response = await confirmRecyclerInvoice(params);
    return response;
  });

  return [
    {
      error: genTransporterInvoice.error || getConfirmInvoice.error,
      loading: genTransporterInvoice.loading || getConfirmInvoice.loading,
      value: genTransporterInvoice.value || getConfirmInvoice.value,
    },
    {
      doGenerateTransporterInvoice,
      doConfirmInvoice,
    },
  ];
}

export function useAdequare() {
  const [pullGSTRFilingsFromAdequare, doPullGSTFilingsFromAdequare] = useAsyncFn(async (params) => {
    const response = await pullGSTFilingsFromAdequare(params);
    return response;
  });

  const [getGSTRFilings, doGetGSTRFilings] = useAsyncFn(async (params) => {
    const response = await getGSTFilings(params);
    return response;
  });

  const [getGSTRFilingsReport, dogetGSTFilingsReport] = useAsyncFn(async (params) => {
    const response = await getGSTFilingsReport(params);
    return response;
  });

  return [
    {
      error: pullGSTRFilingsFromAdequare.error || getGSTRFilings.error,
      loading: pullGSTRFilingsFromAdequare.loading || getGSTRFilings.loading,
      value: pullGSTRFilingsFromAdequare.value || getGSTRFilings.value,
    },
    {
      doPullGSTFilingsFromAdequare,
      doGetGSTRFilings,
      dogetGSTFilingsReport,
    },
  ];
}

export function usePaymentTerms() {
  const [{ error, loading, value = {} }, doGetAllPaymentTerms] = useAsyncFn(async (params) => {
    const response = await getAllPaymentTerms(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: addPayTermErr, loading: loadAddPayTerm, value: valPayTerm },
    doAddPaymentTerm,
  ] = useAsyncFn(async (payload) => {
    const response = await addPaymentTerms(payload);

    return response;
  });
  const [
    { error: editPayTermErr, loading: loadEditPayTerm, value: editValPayTerm },
    doEditPaymentTerm,
  ] = useAsyncFn(async (payload) => {
    const response = await editPaymentTerms(payload);

    return response;
  });

  return [
    {
      error,
      loading,
      value,
      addPayTermErr,
      loadAddPayTerm,
      valPayTerm,
      editPayTermErr,
      loadEditPayTerm,
      editValPayTerm,
    },
    {
      doGetAllPaymentTerms,
      doAddPaymentTerm,
      doEditPaymentTerm,
    },
  ];
}

export function useCampaign() {
  const [
    { error: campaignCreateError, loading: campaignCreateLoading, value: campaignCreateValue },
    doCreateCampaign,
  ] = useAsyncFn(async (payload) => {
    const response = await createCampaign(payload);
    return response;
  });

  const [
    {
      error: campaignCreateDraftError,
      loading: campaignCreateDraftLoading,
      value: campaignCreateDraftValue,
    },
    doCreateCampaignDraft,
  ] = useAsyncFn(async (payload) => {
    const response = await createCampaignDraft(payload);
    return response;
  });

  const [
    { error: campaignsListError, loading: campaignsListLoading, value: campaignsListValue = {} },
    doFetchCampaignsList,
  ] = useAsyncFn(async (params) => {
    const response = await getAllCampaigns(params);
    const data = response?.data;
    const totalCount = Number(response?.headers['x-total-count']) || data?.length;
    return { data, totalCount };
  });

  const [
    { error: campaignDetailsError, loading: campaignDetailsLoading, value: campaignDetailsValue },
    doGetCampaignDetails,
  ] = useAsyncFn(async (id) => {
    const response = await getAllCampaignsById(id);
    return response;
  });

  const [
    {
      error: updateCampaignStatusError,
      loading: updateCampaignStatusLoading,
      value: updateCampaignStatusValue,
    },
    doUpdateCampaignStatus,
  ] = useAsyncFn(async (id, params) => {
    const response = await updateCampaignStatus(id, params);
    return response;
  });

  const [
    {
      error: campaignSellersListError,
      loading: campaignSellersListLoading,
      value: campaignSellerListValue = {},
    },
    doFetchSellersByCampaignsId,
  ] = useAsyncFn(async (campaignId, params) => {
    const response = await getAllSellersByCampaignId(campaignId, params);
    const data = response?.data;
    const totalCount = Number(response?.headers['x-total-count']) || data?.length;
    return { data, totalCount };
  });

  const [
    {
      error: fetchCampaignsForUsersError,
      loading: fetchCampaignsForUsersLoading,
      value: fetchCampaignsForUsersValue,
    },
    doFetchCampaignsForUsers,
  ] = useAsyncFn(async (params) => {
    const response = await getAllCampaignsForUsers(params);
    return response;
  });

  const [
    { error: suggestedPriceError, loading: suggestedPriceLoading, value: suggestedPriceValue },
    doGetSuggestedPrice,
  ] = useAsyncFn(async (params) => {
    const response = await getSuggestedPrice(params);
    return response;
  });

  const [
    { error: aggregatorsError, loading: aggregatorsLoading, value: aggregatorsValue },
    doGetAllAggregators,
  ] = useAsyncFn(async () => {
    const response = await getAllAggregators();
    return response;
  });

  const [
    {
      error: aggregatorsByNameError,
      loading: aggregatorsByNameLoading,
      value: aggregatorsByNameValue,
    },
    doGetAllAggregatorsByName,
  ] = useAsyncFn(async (params) => {
    const response = await getAllAggregatorsByName(params);
    return response;
  });

  return [
    {
      campaignCreateError,
      campaignCreateLoading,
      campaignCreateValue,
      campaignsListError,
      campaignsListLoading,
      campaignsListValue,
      campaignDetailsError,
      campaignDetailsLoading,
      campaignDetailsValue,
      updateCampaignStatusError,
      updateCampaignStatusLoading,
      updateCampaignStatusValue,
      campaignSellersListError,
      campaignSellersListLoading,
      campaignSellerListValue,
      campaignCreateDraftError,
      campaignCreateDraftLoading,
      campaignCreateDraftValue,
      fetchCampaignsForUsersError,
      fetchCampaignsForUsersLoading,
      fetchCampaignsForUsersValue,
      suggestedPriceError,
      suggestedPriceLoading,
      suggestedPriceValue,
      aggregatorsError,
      aggregatorsLoading,
      aggregatorsValue,
      aggregatorsByNameError,
      aggregatorsByNameLoading,
      aggregatorsByNameValue,
    },
    {
      doCreateCampaign,
      doFetchCampaignsList,
      doGetCampaignDetails,
      doUpdateCampaignStatus,
      doFetchSellersByCampaignsId,
      doCreateCampaignDraft,
      doFetchCampaignsForUsers,
      doGetSuggestedPrice,
      doGetAllAggregators,
      doGetAllAggregatorsByName,
    },
  ];
}

/**
 *
 * @returns tracking api hooks
 */
export function useTracking() {
  const [{ error, loading, value = {} }, doSaveOrUpdateETA] = useAsyncFn(async (params) => {
    const response = await saveOrUpdateETA(params);
    return response;
  });

  const [
    { error: errorMetrics, loading: loadMetrics, value: metricsData = {} },
    doGetTrackingMetrics,
  ] = useAsyncFn(async (params) => {
    const response = await getAllTrackingMetrics(params);
    return response;
  });

  const [
    { error: errorTrackManual, loading: loadTrackManual, value: valueTrackManual },
    doTrackManual,
  ] = useAsyncFn(async (id, payload) => {
    const response = await trackManual(id, payload);
    return response;
  });

  const [
    { error: errorTrackingDetails, loading: loadTrackingDetails, value: valueTrackingDetails },
    doGetTrackingDetails,
  ] = useAsyncFn(async (payload) => {
    const response = await getAllTrackingDetails(payload);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: errorTrackingView, loading: loadTrackingView, value: valueTrackingView },
    doGetTrackingView,
  ] = useAsyncFn(async (payload) => {
    const response = await getTrackingView(payload);
    return response?.data;
  });

  return [
    {
      error,
      loading,
      value,
      errorMetrics,
      loadMetrics,
      metricsData,
      errorTrackManual,
      loadTrackManual,
      valueTrackManual,
      errorTrackingDetails,
      loadTrackingDetails,
      valueTrackingDetails,
      errorTrackingView,
      loadTrackingView,
      valueTrackingView,
    },
    {
      doSaveOrUpdateETA,
      doGetTrackingMetrics,
      doTrackManual,
      doGetTrackingDetails,
      doGetTrackingView,
    },
  ];
}

export function useTrackingConfig() {
  const [{ error, loading, value }, trackingConfig] = useAsyncFn(async () => {
    const response = await getTrackingConfig();
    return response;
  });

  const [
    { error: errorConfigSave, loading: loadingConfigSave, value: valueConfigSave },
    storeTrackingConfig,
  ] = useAsyncFn(async (payload) => {
    const response = await saveTrackingConfig(payload);
    return response;
  });

  const [
    { error: errorConfigLogs, loading: loadingConfigLogs, value: valueConfigLogs },
    logTrackingConfig,
  ] = useAsyncFn(async (param) => {
    const response = await getTrackingConfigLogs(param);
    return response;
  });
  return [
    {
      error,
      loading,
      value,
      errorConfigLogs,
      loadingConfigLogs,
      valueConfigLogs,
      errorConfigSave,
      loadingConfigSave,
      valueConfigSave,
    },
    { trackingConfig, logTrackingConfig, storeTrackingConfig },
  ];
}

export function useMarketplaceLogs() {
  const [{ error, loading, value }, doGetMarketplaceLogs] = useAsyncFn(async (params) => {
    const response = await getMarketplaceLogs(params);
    return response;
  });

  const [
    { error: errorMarketplaceLogs, loading: loadingMarketplaceLogs, value: valueMarketplaceLogs },
    doGetMarketplaceLogsByCriteria,
  ] = useAsyncFn(async (params) => {
    const response = await getMarketplaceLogsByCriteria(params);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
      errorMarketplaceLogs,
      loadingMarketplaceLogs,
      valueMarketplaceLogs,
    },
    { doGetMarketplaceLogs, doGetMarketplaceLogsByCriteria },
  ];
}

export function useBankDetails() {
  const [{ error, loading, value }, doUpdateBankDetails] = useAsyncFn(async (params) => {
    const response = await updateBankDetails(params);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
    },
    { doUpdateBankDetails },
  ];
}

export function useCustomers() {
  const [
    { error: errorPOC, loading: loadingPOCS, value: POCValueList = [] },
    doGetPOCByState,
  ] = useAsyncFn(async (params) => {
    const response = await getPOCByState(params);
    return response;
  });

  const [
    { error: errorUpdatePOC, loading: loadUpatePOCS, value: updatePOC = {} },
    doUpdateSPOC,
  ] = useAsyncFn(async (params) => {
    const response = await getUpdateSPOC(params);
    return response;
  });

  const [
    { error: errorGetPOC, loading: loadGetPOCS, value: getPOC = {} },
    doGetPOCByRSM,
  ] = useAsyncFn(async (params) => {
    const response = await getPOCByRSM(params);
    return response;
  });

  return [
    {
      errorPOC,
      loadingPOCS,
      POCValueList,
      errorUpdatePOC,
      loadUpatePOCS,
      updatePOC,
      errorGetPOC,
      loadGetPOCS,
      getPOC,
    },
    { doGetPOCByState, doUpdateSPOC, doGetPOCByRSM },
  ];
}

export function useMarginApproval() {
  const [{ error, loading, value }, marginApproval] = useAsyncFn(async (params) => {
    const response = await setMarginApproval(params);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
    },
    { marginApproval },
  ];
}

/**
 * ==================== Users And Roles Management========================
 */

export function useUserRoles() {
  const [
    { error: errorAllRole, loading: loadingAllRole, value: valueAllRole },
    doGetServiceRoles,
  ] = useAsyncFn(async (params) => {
    const response = await getAllServiceRoles(params);
    return response;
  });

  const [
    { error: errorAllGroupRole, loading: loadingAllGroupRole, value: valueAllGroupRole },
    doGetAllGroupRoles,
  ] = useAsyncFn(async (params) => {
    const response = await getAllRolesGroup(params);
    return response;
  });

  const [
    { error: errorRoleByName, loading: loadingRoleByName, value: valueRoleByName },
    doGetRolesByName,
  ] = useAsyncFn(async (params) => {
    const response = await getRoleByName(params);
    return response;
  });

  const [
    { error: errorAddNewRole, loading: loadingAddNewRole, value: valueAddNewRole },
    doAddNewRole,
  ] = useAsyncFn(async (payload) => {
    const response = await createNewRole(payload);
    return response;
  });

  const [
    { error: errorEditNewRole, loading: loadingEditNewRole, value: valueEditNewRole },
    updateRole,
  ] = useAsyncFn(async (payload) => {
    const response = await editRole(payload);
    return response;
  });

  const [
    { error: errorAllGroups, loading: loadingAllGroups, value: valueAllGroups },
    doGetAllGroups,
  ] = useAsyncFn(async (params) => {
    const response = await getAllGroups(params);
    return response;
  });

  const [
    { error: errorAllModules, loading: loadingAllModules, value: valueAllModules },
    doGetAllModules,
  ] = useAsyncFn(async (params) => {
    const response = await getAllModules(params);
    return response;
  });

  const [
    { error: errorAllMaterialList, loading: loadingAllMaterialList, value: valueAllMaterialList },
    doGetAllMaterialTypesList,
  ] = useAsyncFn(async (params) => {
    const response = await getMaterialTypesList(params);
    return response;
  });

  const [
    { error: errorAllSystemUsers, loading: loadingAllSystemUsers, value: valueAllSystemUsers },
    doGetAllSystemUsers,
  ] = useAsyncFn(async (params) => {
    const response = await getAllSystemUsers(params);

    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });
  const [
    { error: errorValidateToken, loading: loadingValidateToken, value: valueValidateToken },
    doGetValidateToken,
  ] = useAsyncFn(async (params) => {
    const response = await validateToken(params);
    return response;
  });

  const [
    {
      error: errorAllLoginLogoutLogs,
      loading: loadingAllLoginLogoutLogs,
      value: valueAllLoginLogoutLogs,
    },
    doCreateLoginLogoutLogs,
  ] = useAsyncFn(async (params) => {
    const response = await createLoginLogoutLogs(params);
    return response;
  });

  return [
    {
      errorAllRole,
      loadingAllRole,
      valueAllRole,
      errorAllGroupRole,
      loadingAllGroupRole,
      valueAllGroupRole,
      errorAddNewRole,
      loadingAddNewRole,
      valueAddNewRole,
      errorEditNewRole,
      loadingEditNewRole,
      valueEditNewRole,
      valueRoleByName,
      loadingRoleByName,
      errorRoleByName,
      errorAllGroups,
      loadingAllGroups,
      valueAllGroups,
      errorAllModules,
      loadingAllModules,
      valueAllModules,
      valueAllMaterialList,
      valueAllSystemUsers,
      loadingAllSystemUsers,
      valueValidateToken,
      valueAllLoginLogoutLogs,
      loadingAllMaterialList,
    },
    {
      doGetServiceRoles,
      doGetAllGroupRoles,
      doAddNewRole,
      doGetRolesByName,
      doGetAllGroups,
      doGetAllModules,
      updateRole,
      doGetAllMaterialTypesList,
      doGetAllSystemUsers,
      doGetValidateToken,
      doCreateLoginLogoutLogs,
    },
  ];
}

export function useDailyPrices() {
  const [
    { error: getDailyPricesError, loading: getDailyPricesLoading, value: getDailyPricesValue },
    doGetDailyPrices,
  ] = useAsyncFn(async (params) => {
    const response = await getDailyPrices(params);
    return response;
  });

  const [
    {
      error: dailyPriceUpdateError,
      loading: dailyPriceUpdateLoading,
      value: dailyPriceUpdateValue,
    },
    doUpdateDailyPrice,
  ] = useAsyncFn(async (payload) => {
    const response = await updateDailyPrice(payload);
    return response;
  });

  return [
    {
      getDailyPricesError,
      getDailyPricesLoading,
      getDailyPricesValue,
      dailyPriceUpdateError,
      dailyPriceUpdateLoading,
      dailyPriceUpdateValue,
    },
    {
      doGetDailyPrices,
      doUpdateDailyPrice,
    },
  ];
}

export function useUpdateEwaybillNumber() {
  const [updateEwaybill, updateEwaybillNumber] = useAsyncFn(async (payload) => {
    const response = await updateEwaybillNo(payload);
    return response;
  });
  return [
    {
      error: updateEwaybill?.error,
      loading: updateEwaybill?.loading,
      value: updateEwaybill?.value,
    },
    { updateEwaybillNumber },
  ];
}

/**
 * ==================== Requisition Target ========================
 */
export function useAssignTargets() {
  const [assignTarget, targetAssign] = useAsyncFn(async (payload) => {
    const response = await assignTargets(payload);
    return response;
  });
  return [
    {
      error: assignTarget?.error,
      loading: assignTarget?.loading,
      value: assignTarget?.value,
    },
    { targetAssign },
  ];
}
export function useUpdateTarget() {
  const [targetUpdate, updatePocTarget] = useAsyncFn(async (payload) => {
    const response = await updateTarget(payload);
    return response;
  });

  return [
    {
      error: targetUpdate?.error,
      loading: targetUpdate?.loading,
      value: targetUpdate?.value,
    },
    { updatePocTarget },
  ];
}

export function useSegmentation() {
  const [getVendorSegmentationCall, doGetVendorSegmentation] = useAsyncFn(async (params) => {
    const response = await getVendorSegmentation(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  }, []);

  const [saveVendorSegmentationCall, doSaveVendorSegmentation] = useAsyncFn(async (payload) => {
    const response = await saveVendorSegmentation(payload);
    return response;
  });

  const [updateVendorSegmentationCall, doUpdateVendorSegmentation] = useAsyncFn(async (payload) => {
    const response = await updateVendorSegmentation(payload);
    return response;
  });

  const [getSegmentationLogsCall, doGetLogsSegmentation] = useAsyncFn(async (params) => {
    const response = await getSegmentLogs(params);
    return response;
  });
  return [
    {
      error:
        getVendorSegmentationCall?.error ||
        saveVendorSegmentationCall?.error ||
        updateVendorSegmentationCall?.error ||
        getSegmentationLogsCall?.error,
      loading:
        getVendorSegmentationCall?.loading ||
        saveVendorSegmentationCall?.loading ||
        updateVendorSegmentationCall?.loading ||
        getSegmentationLogsCall?.loading,
      value:
        getVendorSegmentationCall?.value ||
        saveVendorSegmentationCall?.value ||
        updateVendorSegmentationCall?.value ||
        getSegmentationLogsCall?.value,
    },
    {
      doGetVendorSegmentation,
      doSaveVendorSegmentation,
      doUpdateVendorSegmentation,
      doGetLogsSegmentation,
    },
  ];
}
export function useActiveSellerTypes() {
  const [getActiveSellerTypesCall, doGetActivateSellerTypes] = useAsyncFn(async () => {
    const response = await getActiveSellerTypes();
    return response?.data;
  });
  return [
    {
      error: getActiveSellerTypesCall?.error,
      loading: getActiveSellerTypesCall?.loading,
      value: getActiveSellerTypesCall?.value,
    },
    {
      doGetActivateSellerTypes,
    },
  ];
}

export function useSegmentCondition() {
  const [getSegmentConditionCall, doSegmentCondition] = useAsyncFn(async () => {
    const response = await getSegmentCondition();
    return response?.data;
  });
  return [
    {
      error: getSegmentConditionCall?.error,
      loading: getSegmentConditionCall?.loading,
      value: getSegmentConditionCall?.value,
    },
    {
      doSegmentCondition,
    },
  ];
}

export function useOrderAllocation() {
  const [
    {
      error: saveUserAvailabilityError,
      loading: saveUserAvailabilityLoading,
      value: saveUserAvailabilityValue,
    },
    doSaveUserAvailability,
  ] = useAsyncFn(async (params) => {
    const response = await userAvailability(params);
    return response;
  });
  const [
    {
      error: updateUserAvailabilityError,
      loading: updateUserAvailabilityLoading,
      value: updateUserAvailabilityValue,
    },
    doUpdateUserAvailability,
  ] = useAsyncFn(async (params) => {
    const response = await updateUserAvailability(params);
    return response;
  });
  const [
    { error: totalUsersError, loading: totalUsersLoading, value: totalUsersValue },
    doGetTotalUsers,
  ] = useAsyncFn(async (params) => {
    const response = await getTotalUsers(params);
    return response;
  });
  const [
    { error: assignSaleOrderError, loading: assignSaleOrderLoading, value: assignSaleOrderValue },
    doAssignSaleOrder,
  ] = useAsyncFn(async (params) => {
    const response = await assignSaleOrder(params);
    return response;
  });
  const [
    {
      error: saleOrderAllocationError,
      loading: saleOrderAllocationLoading,
      value: saleOrderAllocationValue,
    },
    doGetSaleOrderAllocation,
  ] = useAsyncFn(async (params) => {
    const response = await getAssignedSaleOrders(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  });

  const [
    { error: holdSaleOrderError, loading: holdSaleOrderLoading, value: holdSaleOrderValue },
    doHoldSaleOrder,
  ] = useAsyncFn(async (params) => {
    const response = await holdAssignedSaleOrder(params);
    return response;
  });

  const [
    {
      error: saleOrderAllocationLogsError,
      loading: saleOrderAllocationLogsLoading,
      value: saleOrderAllocationLogsValue,
    },
    doGetSaleOrderAllocationLogs,
  ] = useAsyncFn(async (params) => {
    const response = await getSaleOrderAllocationLogs(params);
    return response;
  });

  return [
    {
      saveUserAvailabilityError,
      updateUserAvailabilityError,
      totalUsersError,
      saveUserAvailabilityLoading,
      updateUserAvailabilityLoading,
      totalUsersLoading,
      saveUserAvailabilityValue,
      updateUserAvailabilityValue,
      totalUsersValue,
      assignSaleOrderError,
      assignSaleOrderLoading,
      assignSaleOrderValue,
      saleOrderAllocationError,
      saleOrderAllocationLoading,
      saleOrderAllocationValue,
      holdSaleOrderError,
      holdSaleOrderLoading,
      holdSaleOrderValue,
      saleOrderAllocationLogsError,
      saleOrderAllocationLogsLoading,
      saleOrderAllocationLogsValue,
    },
    {
      doSaveUserAvailability,
      doUpdateUserAvailability,
      doGetTotalUsers,
      doAssignSaleOrder,
      doGetSaleOrderAllocation,
      doHoldSaleOrder,
      doGetSaleOrderAllocationLogs,
    },
  ];
}

export function useOutstandingAmount() {
  const [getOutStandingAmountCall, doOutstandingAmount] = useAsyncFn(async (params) => {
    const response = await getOutStandingAmount(params);
    return response?.data;
  });
  return [
    {
      error: getOutStandingAmountCall?.error,
      loading: getOutStandingAmountCall?.loading,
      value: getOutStandingAmountCall?.value,
    },
    {
      doOutstandingAmount,
    },
  ];
}

export function manageUpdatesApp() {
  const [{ error: postErrors, loading: postLoad, value: postVal }, doCreateAppUpdates] = useAsyncFn(
    async (payload) => {
      const response = await createAppUpdates(payload);
      return response;
    }
  );

  const [{ error, loading, value = {} }, doFetchAppUpdates] = useAsyncFn(async (params) => {
    const response = await fetchAppUpdates(params);
    const totalCount = Number(response?.headers['x-total-count']);
    const data = response?.data;
    return { data, totalCount };
  }, []);

  const [
    { error: getError, loading: getLoader, value: getValue },
    doFetchUpdateDetailsByID,
  ] = useAsyncFn(async (id) => {
    const response = await fetchUpdateDetailsByID(id);
    return response;
  });

  return [
    {
      error,
      loading,
      value,
      postErrors,
      postLoad,
      postVal,
      getError,
      getLoader,
      getValue,
    },
    {
      doFetchAppUpdates,
      doCreateAppUpdates,
      doFetchUpdateDetailsByID,
    },
  ];
}

export function useFinanceDashboard() {
  const [getFinanceDashboard, doGetFinanceDashboard] = useAsyncFn(async () => {
    const response = await financeDashboard();
    return response?.data;
  });
  return [
    {
      error: getFinanceDashboard?.error,
      loading: getFinanceDashboard?.loading,
      value: getFinanceDashboard?.value,
    },
    {
      doGetFinanceDashboard,
    },
  ];
}
