// eslint-disable-next-line import/prefer-default-export
const ApiEndpoints = {
  AUTH: {
    LOGIN: '/stakeholders/authenticate',
    REQUEST_RESET_PASSWORD: '/stakeholders/account/reset_password/init',
    CREATE_PASSWORD: '/stakeholders/account/reset_password/finish',
    GOOGLE_OAUTH2_AUTHENTICATE: '/stakeholders/authenticate-google-auth-token',
    VALIDATE_AUTHENTICATED_USER: '/stakeholders/validate-token',
  },
  USER: {
    ACCOUNT: '/stakeholders/account',
    PROFILE: '/stakeholders/customer/profile',
    DEVICES: '/communication/devices',
    CHANGE_PASSWORD: '/stakeholders/account/change-password',
    GET_ALL_PERMISSIONS: '/stakeholders/epr-permissions',
    GET_ALL_ROLES: '/stakeholders/epr-roles',
    SYSTEM_USERS: '/marketplace/admin/system-users?userId.equals=',
    NOTIFICATIONS: {
      CREATE: '/communication/create-kam-notification',
      CREATE_ROLES: '/communication/create-kam-notification-roles',
      GET: '/communication/get-notifications',
    },
    GET_USERS_BY_ROLE: '/stakeholders/get-user-by-role',
  },
  CITY: {
    FETCH: '/marketplace/regions',
    CREATE: '/stakeholders/regions',
    FETCH_CITIES: '/stakeholders/get-regions-by-page',
    UPDATE: '/stakeholders/regions',
    ZIPCODE: '/stakeholders/region-zip-code',
    STATE: '/stakeholders/regions/state-city/{stateId}',
    UPDATE_CITY: '/stakeholders/update-city-name',
  },
  RECYCLER: {
    FETCH: '/marketplace/recyclers?size=1000',
    FETCH_PAGE: '/marketplace/recyclers',
    FETCH_BY_ITEMS: '/marketplace/recyclers_by_items',
    GET: '/marketplace/recyclers',
    UPDATE: '/marketplace/recyclers',
    DELETE: '/marketplace/recyclers/{id}',
    CREATE: '/marketplace/recyclers',
    DETAILS: '/marketplace/recyclers/{id}',
    USER_TYPE: '/marketplace/user_type/{id}',
    GET_RECYCLER_USER: '/stakeholders/recycler-user',
    ADD_RECYCLER: '/stakeholders/recycler-user',
    UPDATE_RECYCLER: '/stakeholders/recycler-user',
    DELETE_RECYCLER: '/stakeholders/delete-recycler-user',
    CONVERT_BUYER_TO_SELLER: '/stakeholders/convert-buyer-to-seller',
    DEACTIVATE_RECYCLER: '/marketplace/deactivate_recycler',
    UPDATE_POINT_OF_CONTACT: '/marketplace/update-recycler-point-of-contact',
    ADD_EDIT_RECYCLER_ITEMS: '/marketplace/api/update_recycler_items',
    RECYCLER_APP_LEADS: '/stakeholders/customers-by-recyclers',
    RECYCLER_PREFERRED_CATEGORY: '/marketplace/preferred-category',
    VALIDATE_DOCUMENTS: '/stakeholders/documents/update',
    RECYCLER_APP_LEAD_ID: '/stakeholders/service/recycler-by-id',
    RECYCLER_APP_LEAD_AGREEMENT: '/marketplace/recycler-agreement-doc-info',
    GET_SYSTEM_CONFIGS: '/marketplace/SystemConfigs',
  },
  TRANSPORTERS: {
    GET: '/marketplace/transporters_by-page',
    FETCH: '/marketplace/transporters',
    CREATE: '/marketplace/transporters',
    DETAILS: '/marketplace/transporters/{id}',
    UPDATE: '/marketplace/transporters',
    DELETE: '/marketplace/transporters/{id}',
    FETCH_BY_NAME: '/marketplace/transporters-by-name',
  },
  AGGREGATOR: {
    FETCH: '/stakeholders/aggregators',
    DETAILS: '/stakeholders/aggregator/{id}',
    AGGREGATOR_BY_CITY_ID: '/stakeholders/aggregators',
    CHANGE_KAM: '/stakeholders/change-kam',
    GET_DEVICE_DETAILS: '/communication/devices?appCode=MKT006',
    SEND_GSTIN_REMAINDER: '/marketplace/request-gstin',
    SEND_BANK_DETAILS_REMAINDER: '/marketplace/request-bank-details',
    UPDATE_INTERNAL_USER: '/stakeholders/dummy-customer/{id}',
    GET_NOTES: '/stakeholders/admin/customers/{id}/notes',
    ADD_NOTE: '/stakeholders/admin/customers/{id}/notes',
    UPDATE_ADDRESS: '/stakeholders/customer-address',
    CONVERT_SELLER_TO_BUYER: '/stakeholders/convert-seller-to-buyer',
    ADD_SELLER_STATUS: '/stakeholders/add_seller_status',
    GET_SELLER_STATUSES: '/stakeholders/get_seller_status',
    BLOCK_OR_UNBLOCK_SELLER: '/marketplace/block_or_unblock_seller',
    CREATE: '/stakeholders/customer-address',
    AGGREGATOR_CAMPAIGNS: '/marketplace/all-campaigns-for-seller',
    AGGREGATOR_DEFAULTSCORE: '/stakeholders/rating/config',
    AGGREGATOR_RATING_PENDINGACTIVITIES: '/stakeholders/rating/pending',
    AGGREGATOR_RATING_SCORES: '/stakeholders/rating',
    AGGREGATOR_GST_MANUAL_ENTRY: '/stakeholders/rating/gst-manual-entry',
    UPLOAD_CSV: '/stakeholders/rating/upload-csv',
    LEADS: '/stakeholders/rating/leads',
    UPDATE_SELLER: '/stakeholders/update-customer_bank_status/{id}',
    UPDATE_CUSTOMER_ADDRESS: '/stakeholders/mp/customer-address',
    UPDATE_PREFERRED_MATERIAL: '/marketplace/seller-preferred-material',
    AGGREGATOR_ONBOARD: '/stakeholders/aggregator/business-details?isKAM=true',
    AGGREGATOR_GSTIN: '/marketplace/gstin/',
    AGGREGATOR_UPDATE_SELLER_STATUS: '/marketplace/update-seller-status',
    AGGREGATOR_USERS: '/stakeholders/aggregator-user',
    DELETE_AGGREGATOR_USERS: '/stakeholders/delete-aggregator-user',
    EDIT_AGGREGATOR_USERS: '/stakeholders/aggregator-user',
    CREATE_AGGREGATOR_USERS: '/stakeholders/aggregator-user',
    AGGREGATOR_USER_ACTIVITY: '/stakeholders/user-activity',
    UPDATE_AGGREGATOR_E_INVOICE: '/marketplace/update-seller-einvoice',
    APP_LEADS_ADDRESS: '/stakeholders/mp/customer-address',
    APP_LEADS_BANK_DETAILS: '/stakeholders/service/mp/save-bank-details',
    APP_LEADS_AGREEMENT_DOC: '/marketplace/seller-agreement-doc-info',
    PENNY_DROP_BANK_DETAILS: '/marketplace/bank-verification',
    SKIP_AGGREGATOR_DETAILS: '/stakeholders/skip-kyc-details',
    AGGREGATOR_DOCUMENTS_LIST: '/stakeholders/required-document-types',
    AGGREGATOR_ONBOARDING: '/stakeholders/complete-vendor-registration',
    FETCH_GSTIN: '/marketplace/update-gstin-status',
    SEND_SCHEDULED_KYC_DETAILS: '/stakeholders/vkyc/init',
    FETCH_VKYC_DETAILS: '/stakeholders/vkyc/getVkycDetails',
    POST_VKYC_STATUS: '/marketplace/fetchVkyc',
    UPDATE_AMOUNT: '/marketplace/vendor-outstanding-balance-by-zoho-id',
    READY_FOR_ONBOARDING: '/stakeholders/verify-aggregator-onboarding',
  },
  KAM: {
    FETCH: '/stakeholders/admin/all-users?size=1000&roles=ROLE_USER',
    // eslint-disable-next-line max-len
    // '/marketplace/admin/system-users?roles=ROLE_KEY_ACCOUNT_MANAGER,ROLE_ADMIN,ROLE_THIRD_PARTY,ROLE_MARKETPLACE_ACCOUNTS,ROLE_USER&size=1000&sort=id,desc',
    CREATE: '/stakeholders/admin/system-users',
    UPDATE: '/stakeholders/admin/system-users',
    ASSIGN_CITIES: '/stakeholders/kam-regions',
  },
  LISTINGS: {
    FETCH: '/marketplace/listings',
    DETAILS: '/marketplace/listings/{id}',
    APPROVE_LISTING: '/marketplace/listings/mark-as-approve/{id}',
    REJECT_LISTING: '/marketplace/listings/mark-as-reject/{id}',
    LISTING_PRICE_UPDATE: '/marketplace/update-listing',
    UPDATE_LISTING: '/marketplace/listings',
    ADD_EDIT_LISTING: '/marketplace/add-item-to-listing',
    CREATE_LISTING: '/marketplace/listings',
    GET_PO: '/marketplace/open-pos/{id}',
    ASSIGN_OPEN_PO: '/marketplace/listing-order',
    DISABLE_LISTING: '/marketplace/listings/mark-as-deactivate/{id}',
    DELETE_LISTING_ITEM: '/marketplace/delete-listing-item/{id}',
    METRICS: '/marketplace/listings/metrics',
    ACTIVATE_LISTING: '/marketplace/listings/mark-as-activate/{id}',
    FETCH_LISTING_ITEM: '/marketplace/item-listings',
    LISTING_BUYER_PRICE: '/marketplace/save-buyer-price',
    SPLIT_LISTING: '/marketplace/listing/split',
    ASSIGN_REQUISITION: '/marketplace/add-listing-to-requisition',
    LISTING_BUYER_ROUTE: '/marketplace/buyers-with-existing-route/{id}',
    LISTING_PRICE_TREND: '/marketplace/edit-price',
    LISTING_SCHEDULE: '/marketplace/scheduled-pickup-at',
    INITIATE_AUCTION: '/auction/auctions',
    ASSET_ITEM_LIST: '/marketplace/item-custom-attr-val-mapping',
  },
  USERS: {
    FETCH: '/stakeholders/admin/all-users?size=1000&roles=ROLE_USER',
    // '/marketplace/admin/system-users?' +
    // // eslint-disable-next-line max-len
    // 'roles=ROLE_KEY_ACCOUNT_MANAGER,ROLE_ADMIN,ROLE_MARKETPLACE_ACCOUNTS,
    // ROLE_MARKETPLACE_BUSINESS,ROLE_SPOC,ROLE_FULFILMENT_USER&size=1000&sort=id,desc',
    CREATE: '/stakeholders/admin/system-users',
    UPDATE: '/stakeholders/admin/system-users',
  },
  ADDRESS: {
    PAN: '/stakeholders/pan-info',
    PINCODE: 'core/public/cities?zipcode=',
    GSTIN: '/stakeholders/address-info/',
    BUSINESS_DETAILS: '/stakeholders/customer/business-details',
  },
  LOGISTICS: {
    FETCH: '/marketplace/logistic-costs?sort=modifiedAt,desc&size=100000',
    UPDATE: '/marketplace/logistic-costs',
    DELETE: '/marketplace/logistic-costs',
    FETCH_LOGISTIC: '/marketplace/logistic-cost-logs?sort=createdAt,desc&size=100000',
    GET: '/marketplace/logistic-costs/routes',
  },
  CAMPAIGNS: {
    REPORTS: '/communication/reports',
    USERS: '/communication/users-info',
  },
  CATALOG: {
    ITEMS: '/marketplace/items?active.equals=true&size=400',
    ITEM_QPARAM: '/marketplace/item-config',
  },
  REGION: '/marketplace/regions',
  PRICING: '/marketplace/offers',
  REQUISITION: {
    FETCH: '/marketplace/requisitions',
    FETCH_ALL_REQUISITIONS: '/marketplace/requisition',
    FETCH_ALL_REQUISITIONS_ASSIGN_REQ: '/marketplace/requisition-assign-buyer',
    CREATE_REQUISITION: '/marketplace/requisition',
    FETCH_REQUISITION_BY_ID: '/marketplace/requisition/{id}',
    ALTERNATE: '/marketplace/alternative-requisitions/{id}',
    REASSIGN: '/marketplace/sale-orders/assign-requisition',
    REQUISITION_METRICS: '/marketplace/fetch-requisition-metrics',
    QUOTES: {
      FETCH_UPDATE_QUOTE: '/marketplace/quote',
    },
    ASSIGN_TARGETS: '/marketplace/create-targets',
    UPDATE_TARGET: '/marketplace/update-target',
    // ALTERNATE: 'http://localhost:8080/alternative-demands',
  },
  BUYERSELLERMAPPING: {
    FETCH: '/marketplace/user_type',
    FETCH_PROFILE: '/marketplace/profile-mappings',
    UPDATE_PROFILE: '/marketplace/buyer-seller-mapping',
    FETCH_PROFILE_BY_ID: '/marketplace/aggregator-mapping/{id}',
    TOGGLE_LISTING: '/marketplace/mapping-active-inactive',
    CHANGE_AGGREGATOR_USER: '/stakeholders/aggregator/profile',
  },
  DOCUMENT: {
    DISPLAY: '/core/documents/display',
    SIGNED_URL: '/marketplace/signed-url',
    SIGNED_URL_STAKEHOLDER: '/stakeholders/documents/signed-url',
    CHANGE_DOC_TYPE: '/marketplace/doc-type',
    ATTACH_DOCUMENT: '/marketplace/attach',
    ATTACH_DOCUMENT_STAKEHOLDER: '/stakeholders/documents/attach',
    DELETE_DOC: '/marketplace/delete/{id}',
    DELETE_DOC_STAKEHOLDER: '/stakeholders/documents/remove?id=',
    /** @deprecated */
    RECYCLER_SIGNED_URL: '/stakeholders/documents/signed-url',
    /** @deprecated */
    PURCHASE_ORDER_SIGNED_URL: '/marketplace/signed-url',
    /** @deprecated */
    ATTACH: {
      DOCUMENT_ATTACH: '/marketplace/attach',
      CATALOG: '/catalog/documents/attach',
      RECYCLER_ATTACH: '/stakeholders/documents/attach', // @deprecated
      PURCHASE_ORDER_ATTACH: '/marketplace/attach', // @deprecated
    },
    /** @deprecated */
    DELETE: {
      CORE: '/core/documents/remove',
      CATALOG: '/catalog/documents/remove',
      RECYCLER_DOC_DELETE: '/stakeholders/documents/remove?id=',
      PURCHASE_ORDER_DELETE: '/marketplace/delete/{id}',
    },
    UPDATE: '/marketplace/update-document',
  },
  ORDER: {
    SALE: {
      FETCH: '/marketplace/sale-orders',
      GET: '/marketplace/sale-orders/{id}',
      MODIFY: '/marketplace/sale-orders',
      UPDATE: 'http://localhost:8080/sale-orders/{id}',
      DELETE: 'http://localhost:8080/sale-orders/{id}',
      REJECT: '/marketplace/cancel-sale-order',
      CHANGE_KAM: '/marketplace/sale-orders/update-kam',
      NOTES: '/marketplace/notes',
      FETCH_TRANSPORTERS: '/marketplace/transporters',
      FETCH_VEHICLES: '/marketplace/vehicles',
      COMMENTS: '/marketplace/comments',
      DELAY_COMMENTS: '/marketplace/comments?type =”TRUCK_PICKUP_SCHEDULED_DELAYED”',
      ADD_UPDATE_TRUCK_DETAILS: '/marketplace/truck-driver-details',
      UPDATE_ASSESSMENT: '/marketplace/sale-order-assessment',
      REASSIGN_ORDER: '/marketplace/assign-requisition',
      APPROVE: '/marketplace/approve-sale-order',
      AGGREGATOR_APPROVAL: '/marketplace/send-approval',
      ASSIGN_PO: '/marketplace/sale-orders/assign-po-number',
      REQUEST_IMAGE: '/marketplace/request-item-image',
      WEIGHBRIDGE_INFO: '/marketplace/weighbridge-info',
      SELLER_WEIGHBRIDGE_INFO: '/marketplace/seller-weighbridge-info',
      MARK_MATERIAL_LOADED: '/marketplace/mark-as-material-loaded',
      LOGISTICS_DOC_UPLOADED: '/marketplace/mark-as-logistics-doc-uploaded',
      MARK_PAYMENT_DONE: '/marketplace/mark-as-payment-done',
      ADD_PAYMENT_RECEIVED: '/marketplace/payment-received',
      // SEND_DRIVER_CONSENT_SMS: '/tracking/subscribe',
      AUTHORIZE_SUBSCRIBE_STAGE: 'https://stage.fms.recykal.com/api/authenticate',
      AUTHORIZE_SUBSCRIBE_PROD: 'https://fms.recykal.com/api/authenticate',
      SEND_DRIVER_CONSENT_SMS_STAGE: 'https://stage.fms.recykal.com/api/tracking/subscribe',
      SEND_DRIVER_CONSENT_SMS_PROD: 'https://fms.recykal.com/api/tracking/subscribe',
      // END_DRIVER_TRIP: '/tracking/service/end-driver-trip',
      END_DRIVER_TRIP_STAGE: 'https://stage.fms.recykal.com/api/tracking/unsubscribe',
      END_DRIVER_TRIP_PROD: 'https://fms.recykal.com/api/tracking/unsubscribe',
      UNSUBSCRIBE_BY_MOBILE: '/marketplace/service/tracking-unsubscribe-by-mobileNo',
      SMS_DOWNLINK: '/marketplace/send-sms-to-driver/',
      SMS_CONSENTLINK: '/marketplace/driver-sms-for-enabling-tacking',
      DELETE_PAYMENT: '/marketplace/delete-payment',
      UPDATE_PAYMENT_TERM: '/marketplace/aggr-payment-terms',
      MARK_DISPATCH: '/marketplace/mark-as-dispatched',
      TRACKTRUCK: '/tracking/track-shipments?orderId.equals=',
      RECEIVED_BY_RECYCLER: '/marketplace/mark-as-received-by-recycler',
      REJECTED_QTY: '/marketplace/rejected-qty',
      QC_DONE: '/marketplace/mark-as-qc-done',
      MARK_EPR: '/marketplace/move-to-epr',
      RECYCLER_QC_PARAMS: '/marketplace/recycler-qc-param',
      DEBIT_NOTE: '/marketplace/add-debit-note-info',
      DEBIT_NOTE_SELLER: '/marketplace/add-debit-note-info-seller',
      PAYMENT_RECEIVED: '/marketplace/payment-received',
      PICKUP_DETAILS: '/marketplace/pickup-details',
      DISPATCH_DETAILS: '/marketplace/dispatched-details',
      DELIVERY_DETAILS: '/marketplace/delivery-details',
      UPDATE_PAYMENT: '/marketplace/update-payment',
      MAKE_PAYMENT: '/marketplace/make-payment',
      MAKE_GST_PAYMENT: '/marketplace/make-gst-payment',
      VERIFY_QUANTITY: '/marketplace/verify-order/{id}',
      ADMIN_VERIFY: '/marketplace/admin-verification/{id}',
      SEND_EMAIL_ADMIN: '/marketplace/send-email-admin-verified/{id}',
      ADD_TRANSPORT_INFO: '/marketplace/sale-order-infos',
      ACCOUNTS_APPROVAL: '/marketplace/accounts-team-verification/{id}',
      ACCOUNTS_SEND_EMAIL: '/marketplace/send-email-accounts-rejected/{id}',
      BUSINESS_HEAD_APPROVAL: '/marketplace/mark-as-order-verified/{id}',
      BUSINESS_HEAD_REJECT: '/marketplace/send-order-rejected-mail/{id}',
      UPDATE_AGGREGATOR_WEIGHBRIDGE_DETAILS: '/marketplace/aggregator_weighbridge-info',
      UPDATE_DOCS_UPLOAD: '/marketplace/docs-upload',
      GET_AVAILABLE_LISTINGS: '/marketplace/item-available-supply',
      GET_AVAILABLE_LISTINGS_PO: '/marketplace/available-supplies',
      GET_ALL_AGGREGATOR_PAY_TERMS: '/marketplace/payment-term-config',
      GET_SELLER_GST: '/marketplace/pending-deductions',
      UPDATE_SELLER_GST: '/marketplace/bulk-gst-payments',
      PRICE_TREND: '/marketplace/update-seller-price',
      // eslint-disable-next-line max-len
      PRICE_TREND_COMMENTS:
        '/marketplace/order-item-logs?size=10000&sort=modifiedAt,desc&saleOrderItemId.equals=',
      TRANSPORTER_PAYMENT_TERM: '/marketplace/transport-payment-term/',
      ADD_SECOND_ITEM: '/marketplace/add-second-item-so-po-listing',
      DELETE_SO_ITEM: '/marketplace/delete-second-item-so-po-listing',
      SEND_DEBIT_NOTE_TO_ZOHO: '/marketplace/send-debit-note-to-zoho',
      SEND_DEBIT_NOTE: '/marketplace/send-debit-note',
      APPROVE_NOTES_SELLER_BUYER: '/marketplace/approve-note-to-buyer-or-seller',
      GET_ALL_INCORRECT_DOCS: '/marketplace/get-all-incorrect-docs',
      SAVE_INCORRECT_DOCS: '/marketplace/add-incorrect-documents/{id}',
      RETURN_SALE_ORDER: '/marketplace/sale-order-return',
      RESELL_SALE_ORDER: '/marketplace/sale-order-resell',
      TRUCK_DRIVER_DETAILS_BY_SALE_ORDER:
        '/marketplace/truck-driver-details-by-sale-order/{saleOrderId}',
      TRACKING: {
        UPDATE_ETA: '/marketplace/updateETA',
        GET_TRACKING_METRICS: '/marketplace/tracking-order-metrics',
        TRACK_MANUAL: '/marketplace/track-manual/{saleOrderId}',
      },
      UPDATE_PICKUP_ADDRESS: '/marketplace/update-pickup-address/{id}',
      PICKUP_ADDRESS_LOGS: '/marketplace/track/logs/config',
      PAYMENTS: {
        AGGREGATOR_PAYMENTS: '/marketplace/seller-payment/metrics',
        AGGREGATOR_PAYMENTS_LIST: '/marketplace/seller-payment/get-aggregator-payments',
        AGGREGATOR_PAYMENTS_LIST_BY_LEVELS:
          'marketplace/seller-payment/get-aggregator-payments-by-levels',
        TRANSPORTER_PAYMENTS_LIST: '/marketplace/transporter-payment/get-transporter-payments',
        TRANSPORTER_PAYMENTS_METRICS: '/marketplace/transporter-payment/metrics',
        APPROVE_PAYMENT: '/marketplace/approve-payment',
      },
      ASSIGN_TRUCK: '/marketplace/seller-assiging-truck',
      SALE_ORDER_RECYCLER_QC_PARAMS: {
        CALCULATE_QC_AMOUNT: '/marketplace/calculate-qc-data/{action}',
        SAVE_RECYCLER_QC_PARAMS: '/marketplace/sale-order-recycler-qc-param',
        GET_RECYCLER_QC_PARAMS: '/marketplace/sale-order-recycler-qc-param',
      },
      MARGIN_DIP: '/marketplace/margin-dip-approval',
      VERIFY_VEHICLE_DETAILS: '/marketplace/verify-vehicle-details',
      APPROVE_REJECT_DOCUMENT: '/marketplace/approve-reject-documents',
      GENERATE_INVOICES: '/marketplace/generate-invoices',
      UPDATE_EWAYBILL_NUMBER: '/marketplace/update-e-way-number',
    },
    PURCHASE: {
      CREATE_PURCHASE_ORDER: '/marketplace/purchase-order',
      CREATE_PURCHASE_ORDER_ADMIN: '/marketplace/listing-po-kam',
      PURCHASE_ORDER_LIST: '/marketplace/purchase-order',
      PURCHASE_ORDER_DETAILS: '/marketplace/purchase-order/{id}',
      UPDATE_PURCHASE_ORDER: '/marketplace/purchase-order',
      APPROVE_PURCHASE_ORDER: '/marketplace/po-approve',
      OPEN_PURCHASE_ORDER: '/marketplace/verify-payment',
      RECORD_ADVANCE_PAYMENT: '/marketplace/payment',
      SAVE_PURCHASE_ORDER: '/marketplace/update-listing-po',
      REJECT_PO: '/marketplace/po-reject/{id}',
    },
    CORE: {
      ALL: '/core/orders',
      VEHICLE_TYPES: '/core/logistics-vehicle',
      ORDER_INFO: '/core/orders/order-info',
      MARK_AS_RECEIVED: '/core/orders/mark-as-received',
      MARK_AS_COMPLETED: '/core/orders/complete',
    },
  },
  MARKET: {
    LISTING: {
      OFFERS: {
        ALL: '/catalog/listings/offers',
      },
      BIDS: {
        ALL: '/catalog/listings/bids',
      },
      STATUS: '/catalog/listings/status',
      // eslint-disable-next-line max-len
      FETCH_AGGREGATOR_NO_OF_DAYS: '/marketplace/payment-term-logs',
    },

    STATS: {
      OFFERS_BY_CAT: '/catalog/listings/stat/offers/category',
      BIDS_BY_CAT: '/catalog/listings/stat/bids/category',
      ITEM_BID: '/catalog/listings/stat/bids/item',
      ITEM_OFFER: '/catalog/listings/stat/offers/item',
      BEST_PRICE: '/catalog/listings/best-price',
    },
  },

  OTHER: {
    PORTAL_CONFIG: '/core/config/portal_config',
    BUSINESS_TYPES: '/stakeholders/public/business-types?size=1000',
    DELETE_ITEM_INVOICE: '/marketplace/so-tranporter-invoive-item/{id}',
  },

  GSTR_RECONCILATION: {
    GSTR_2A_DATA: '/marketplace/gst/fetch-2a/',
    GSTR_2A_PERIOD_DATA: '/marketplace/gst/2a',
    GSTR_2A_FETCH_LOGDATA: '/marketplace/gst/2a-fetch-log',
    GSTR_FETCH_SUMMARY_DATA: '/marketplace/gst/2a-summary',
    GST_FETCH_DATA: '/marketplace/gst/2a/invoice-details',
    GST_FETCH_NOTES_DATA: '/marketplace/gst/2a/cdn-details',
    RECONCILATION_DATA: '/marketplace/reconcile/summary/',
    RECONCILED_DATA: '/marketplace/reconcile',
    RECONCILE_DOCUMENT_DATA: '/marketplace/reconcile/list',
    MATCH_BOOKS_2A_DATA: '/marketplace/reconcile/fetch-missing-inv/',
    LINK_DATA: '/marketplace/reconcile/link',
    ACCEPT_DATA: '/marketplace/reconcile/accept',
    IGNORE_DATA: '/marketplace/reconcile/ignore',
    PAYMENT_SUMMARY: '/marketplace/reconcile/payment-summary',
    RECONCILE_STATUS: '/marketplace/reconcile/status',
    RECONCILE_REPORT: '/marketplace/reconcile/payment-download',
    GSTR_2A_REPORTS: '/marketplace/gst/download/',
  },

  RECYKAL_MARGIN: {
    MARGIN: '/marketplace/item-state-configs',
    MARGIN_QRF: '/marketplace/item-state-config-by-criteria',
  },
  CREDIT_LIMIT: {
    SAVE_OR_LIMIT: '/marketplace/credit-limit-config',
    UPDATE_CREDIT_LIMIT: '/marketplace/credit-limit-config',
  },
  RECYCLER_PAYMENT_TERM: {
    RECYCLER_PAYMENT_TERM: '/marketplace/recycler-payment-term',
  },
  RECYCLER_CREDIT_LIMIT: {
    RECYCLER_CREDIT_LIMIT: '/marketplace/credit-limit-config',
    CREDIT_LIMIT_GLOBAL: '/marketplace/credit-limit-config/global',
  },
  CHANGE_LOG_METRICS: {
    CHANGE_LOG_METRICS: '/marketplace/change-log-metrics',
  },
  RECEIVABLES_SUMMARY: {
    RECEIVABLES_SUMMARY: '/marketplace/recycler-receivables',
    RECEIVABLES_SUMMARY_V2: '/marketplace/recycler-receivables-v2',
    RECEIVABLES_SUMMARY_METRICS: '/marketplace/recycler-receivables-metrics',
    RECYCLERS_RECEIVABLES: '/marketplace/recycler/receivables',
    RECYCLERS_RECEIVABLES_METRICS: '/marketplace/recycler/receivables-metrics',
    RECYCLERS_RECEIVABLES_PAYMENTS: '/marketplace/recycler/receivables-payment',
    RECYCLERS_RECEIVABLES_INVOICES: '/marketplace/recycler/receivables-invoices',
  },
  RECYCLER_LEDGER: {
    RECYCLER_LEDGER: '/marketplace/recycler-ledger',
  },
  RECEIVABLES_INVOICES_SUMMARY: {
    INVOICES_SUMMARY: '/marketplace/recycler-invoices',
  },
  BULK_INVOICE_PAYMENTS: {
    BULK_INVOICE_PAYMENTS: '/marketplace/bulk-payments',
  },
  ITEMS_MODULE: {
    ITEM_INFO: '/marketplace/getCategoriesAndItems',
    TYPES: '/marketplace/getMaterialTypes',
    ADD_TYPE: '/marketplace/material_type',
    ADD_ENTITY: '/marketplace/createCatsAndItems',
    UPDATE_STATUS: '/marketplace/updateCategoryStatus',
    ADD_QUALITY_PARAMS: '/marketplace/addItemQualtyParams',
    ITEM_RANK: '/marketplace/items',
    LOGS: '/marketplace/getItemLogs/{id}',
    MATERIAL_TYPE_TINY: '/marketplace/getMaterialTypesList',
    ADD_CATEGORIES: '/marketplace/categories',
    GET_ZOHO_ITEM_NAME: '/marketplace/item-by-zohoId',
    EDIT_ITEM_RANK: '/marketplace/update-item',
    GET_ITEMS_LIST: '/marketplace/item-by-criteria',
    SELLER_STATUS: '/marketplace/seller-agreement-status',
    CUSTOM_ATTRIBUTES: '/marketplace/item-custom-params-by-itemNo',
    STANDARD_ATTRIBUTES: '/marketplace/item-custom-params',
    NEW_CUSTOM_ATTRIBUTES: '/marketplace/item-custom-params',
    EDIT_CUSTOM_ATTRIBUTES: '/marketplace/item-custom-params',
    CREATE_LISTING_MANUALLY: '/marketplace/item-customAttr-val-mapping',
    GET_QUALITY_PARAM: '/marketplace/item-quality-params',
    GET_CUSTOM_PARAMS_ITEM: '/marketplace/item-custom-params-by-itemNo-standard-type',
  },

  LOGISTIC_ITEM_MSTER: {
    GET: '/marketplace/logistic-item-master',
    FETCH: '/marketplace/logistic/items',
    CREATE: '/marketplace/logistic/items',
  },
  ZOHO_INVOICE: {
    CREATE_TRANSPORTER_INVOICE: '/marketplace/zoho/bill-new',
    GET_INVOICE: '/marketplace/zoho/invoice-new',
  },
  GST_FILINGS: {
    FETCH: '/marketplace/gst/filings',
    PULL_FROM_ADEQUARE: '/marketplace/gst/pull/filings',
    DOWNLOAD: '/marketplace/gst/gst-filing-download',
  },

  CAMPAINGS_CREATION: {
    CREATE_CAMPAINGS: '/marketplace/create-campaign',
    CREATE_CAMPAING_DRAFT: '/marketplace/create-campaign-Draft',
    CAMPAIGN_LIST: '/marketplace/campaigns',
    CAMPAIGN_BY_ID: '/marketplace/campaign-by-id/{id}',
    CAMPAIGN_STATUS_UPDATE: '/marketplace/campaign/{id}',
    CAMPAIGN_SELLER_INFO: '/marketplace/seller-campaigns/',
  },
  FIND_USER: {
    FETCH_USER_CAMPAINGS: '/marketplace/finding-users',
    GET_SUGGESTED_PRICE: '/marketplace/get-suggested-price',
    FETCH_AGGREGATORS: '/marketplace/all-aggregators',
    AGGREGATORS_BY_NAME: '/marketplace/all-aggregators-by-name',
  },
  PAYMENT_TERMS: {
    FETCH: '/marketplace/all-payment-term-config',
    ADD_PAYMENT_TERM: '/marketplace/add-payment-term-config',
    EDIT_PAYMENT_TERM: '/marketplace/edit-payment-term-config',
  },
  TRACKING_CONFIG: {
    TRACKING_CONFIG: '/marketplace/track/config',
    TRACKING_CONFIG_LOGS: '/marketplace/track/logs/config',
  },
  TRACKING: {
    TRACKING_DETAILS: '/marketplace/tracking-details',
    TRACKING_VIEW: '/marketplace/dynamic/view',
  },
  MARKETPLACE_LOGS: {
    GET_LOGS: '/marketplace/marketplace-logs/get-logs',
    GET_LOGS_BY_CRITERIA: '/marketplace/marketplace-logs/logs-by-criteria',
  },
  BANK_DETAILS: {
    UPADTE_BANK_DETAILS: '/stakeholders/bank-details',
  },
  POINT_OF_CONTACT: {
    GET_POC_BY_STATE: '/stakeholders/point-of-contact/spoc-by-state',
    UPDATE_SPOC: '/stakeholders/update-spoc',
    GET_POC_BY_RSM: '/stakeholders/point-of-contact/poc-by-rsm',
  },

  USER_AND_ROLES: {
    GET_ROLES: '/stakeholders/service/authority',
    GET_ROLES_GROUP: '/stakeholders/service/groups',
    CREATE_NEW_ROLE: '/stakeholders/service/authority',
    EDIT_ROLE: '/stakeholders/service/authority',
    GET_ROLES_BY_NAME: '/stakeholders/service/authority/{name}',
    GET_ALL_GROUPS: '/stakeholders/service/groups',
    GET_ALL_MODULES: '/stakeholders/service/module',
    GET_ALL_ZONES: '/stakeholders/service/regions-by-type?type=ZONE',
    GET_ALL_USERS_LIST: '/stakeholders/admin/all-users?roles=ROLE_USER',
    LOGS_LOGIN_LOGOUT: '/stakeholders/logs-for-login-logout',
  },

  DAILY_PRICES: {
    GET_DAILY_PRICES: '/marketplace/daily-prices',
    UPDATE_DAILY_PRICES: '/marketplace/update-daily-price',
  },
  SEGMENTATION: {
    GET_VENDOR_SEGMENT: '/stakeholders/vendorSegment',
    GET_ACTIVE_SELLER_TYPES: '/stakeholders/service/get-active-sellers-types',
    GET_SEGMENT_CONDITIONS: 'stakeholders/getSegmentConditions',
    GET_LOGS: 'stakeholders/vendorSegment/logs',
  },
  ORDER_ALLOCATION: {
    UPDATE_USER_AVAILABLE: '/marketplace/user-available',
    TOTAL_USERS: '/marketplace/total-users',
    ASSIGN_SALE_ORDER: 'marketplace/assign-sale-order',
    GET_SALE_ORDER_ALLOCATION: 'marketplace/sale-order-allocations',
    HOLD_ASSIGNED_SALEORDER: 'marketplace/hold-assigned-sale-order',
    SALE_ORDER_ALLOCATION_LOGS: 'marketplace/sale-order-allocation-logs',
  },
  MANAGE_UPDATES: {
    UPDATES_LIST: 'marketplace/communication-policy-consent',
    GET_UPDATES_ID: 'marketplace/communication-policy-consent/{id}',
    CREATE_UPDATES: 'marketplace/communication-policy-consents',
  },
  FINANCE: {
    DASHBOARD: '/marketplace/finance-dashboard',
  },
};

export default ApiEndpoints;
